import React, { useEffect, useState } from 'react';
import ProductCard from './ProductCard';
import { about2 } from '../../../assets/images';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import './Products.css'

const categories = [
  "Stomatoloji avadanlıq",
  "Stomatologiya",
  "Lazer avadanlığı",
  "Kosmetologiya",
  "Kosmetoloji avadanlıq"
];

const Products = () => {
  const { t } = useTranslation();
  const [list, setList] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("Stomatoloji avadanlıq");
  const [isLoading, setIsLoading] = useState(true);
  
        const [activeIndex, setActiveIndex] = useState(0);
        const [showText, setShowText] = useState(false);
        const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    getProducts();
  }, []);

  useEffect(() => {
    filterProducts(selectedCategory);
  }, [list, selectedCategory]);

  const getProducts = async () => {
    try {
      const response = await axios.get(
        `https://www.adb.az/arash/dashboard.php`,
        { withCredentials: false }
      );
      setList(response.data.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const filterProducts = (category) => {
    const filtered = list.filter(product => product.category === category).slice(0, 4);
    setFilteredProducts(filtered);
  };

  if (isLoading) {
    return <p className="text-center text-white">Loading...</p>;
  }

  return (
    <div className="max-w-containerSm md:max-w-container mx-auto mt-[88px] md:mt-[128px]">
      <div className='flex justify-between items-center'>
        <div className='flex space-x-5 overflow-x-auto whitespace-nowrap scrollbar-hide font-firstFont text-[16px] font-semibold md:text-[18px] md:font-bold'>
          {categories.map(category => (
            <button 
              key={category} 
              onClick={() => setSelectedCategory(category)}
              className={`px-4 py-2 rounded-[32px] ${selectedCategory === category ? 'bg-[#2264DC] text-white' : 'bg-white border border-[#CFCFCF] text-[#2264dc]'}`}
            >
              {category}
            </button>
          ))}
        </div>
      </div>
      <div className="md:grid grid-cols-1 md:grid-cols-4 gap-6 mt-6 md:mt-[54px] hidden">
        {filteredProducts?.map((product) => { 
          let images = [];
          try {
            images = product.images ? JSON.parse(product.images) : [];
          } catch (error) {
            console.error("JSON parse error:", error);
          }
          return (
            <a key={product.id} href={`/product-detail/${product.id}`}>
              <ProductCard
              id={product.id}
                name={product.name}
                description={product.description}
                preferences={product.preferences}
                garanty={product.garanty}
                loan={product.loan}
                credit={product.credit}
                new={product.new}
                discount={product.discount}
                technical={product.technical}
                images={images} 
              />
            </a>
          );
        })}
      </div>
      <div className='block md:hidden mt-[48px] '>
        <Swiper
          slidesPerView={1}
          spaceBetween={20}
          loop={true}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          onSlideChange={(swiper) => {
            setShowText(false);
            setShowButton(false); 
            setActiveIndex(swiper.activeIndex);
          }}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper"
        >
          {filteredProducts?.map((product, index) => {
            let images = [];
            try {
              images = product.images ? JSON.parse(product.images) : [];
            } catch (error) {
              console.error("JSON parse error:", error);
            }
      
            return (
              <SwiperSlide key={index}>
                <ProductCard
                  id={product.id}
                  name={product.name}
                  description={product.description}
                  preferences={product.preferences}
                  garanty={product.garanty}
                  loan={product.loan}
                  credit={product.credit}
                  new={product.new}
                  discount={product.discount}
                  technical={product.technical}
                  images={images}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
        <a className='block md:hidden' href='/products'>
                       <div className='flex justify-between items-center font-secondFont rounded-[16px] bg-[#2264DC] text-white w-full mt-[48px] h-[44px] px-[20px]'>
                          <p>Hamısına bax</p>
                          <img className='h-6 w-6' src={about2}></img>
                        </div>
                       </a>
      </div>
    </div>
  );
};

export default Products;