import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { t } from "i18next";
import './Carousel.css'
import { carousel1, carousel2, carousel3, hero1, hero2, logo } from "../../../assets/images";
import { useTranslation } from "react-i18next";

const Carousel = () => {

  const { t, i18n } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0);
  const [showText, setShowText] = useState(false);
  const [showButton, setShowButton] = useState(false);

  const slides = [
    { img: carousel1, text: "Lazer, stomotoloji və kosmetoloji məhsulların satışı və servisi", content: "Sizin üçün etibarlı və yüksək keyfiyyətli tibbi və estetik avadanlıqları təqdim edirik. Peşəkar servis və geniş çeşidli məhsullarımızla hər zaman yanınızdayıq." },
    { img: carousel2, text: "Lazer, stomotoloji və kosmetoloji məhsulların satışı və servisi", content: "Sizin üçün etibarlı və yüksək keyfiyyətli tibbi və estetik avadanlıqları təqdim edirik. Peşəkar servis və geniş çeşidli məhsullarımızla hər zaman yanınızdayıq." },
   
  ];

  useEffect(() => {
    const textTimer = setTimeout(() => {
      setShowText(true);
    }, 500);

    const buttonTimer = setTimeout(() => {
      setShowButton(true);
    }, 1500);

    return () => {
      clearTimeout(textTimer);
      clearTimeout(buttonTimer);
    };
  }, [activeIndex]);

  return (
    <div className="w-full mx-auto">
      {/* Header is placed outside the Swiper for it to stay on top */}

      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 10000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        onSlideChange={(swiper) => {
          setShowText(false);
          setShowButton(false);
          setActiveIndex(swiper.activeIndex);
        }}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      > 
        {slides.map((slide, index) => (
          <SwiperSlide key={index}>
            <div className="relative">
              <img src={slide.img} alt={`Slide ${index + 1}`} className="w-full h-[447px] md:h-[615px] object-cover" />
              {activeIndex === index && (
                <div className="absolute inset-0 flex flex-col justify-center items-center lg:items-start text-white p-4 lg:px-7 py-5 transition-opacity duration-1000">
                  <div className="md:w-[820px] relative mx-auto text-center">
                    <h2 className={`text-white text-[24px] font-bold md:text-[48px] md:font-semibold font-firstFont transition-all duration-1000 transform ${showText ? 'translate-y-0 opacity-100' : '-translate-y-10 opacity-0'}`}>
                      {t(slide.text)}
                    </h2>
                    <h2 className={` text-white text-[14px] md:text-[16px] mt-[25px] transition-all font-secondFont duration-1000 transform ${showText ? 'translate-y-0 opacity-100' : '-translate-y-10 opacity-0'}`}>
                      {t(slide.content)}
                    </h2>

                    <div className="">
                      <a href="/about">
                        <button className={`w-[203px] h-[44px] bg-[white] text-[#162D59] font-secondFont rounded-[16px] mt-[46px] transition-all duration-1000 transform ${showButton ? 'translate-y-0 opacity-100' : '-translate-y-10 opacity-0'}`}>
                          {t("Ətraflı")}
                        </button>
                      </a>
                    </div>
            
                  </div> 
                </div>
              )}
            </div>
          </SwiperSlide>
        ))} 
      </Swiper>
    </div>
  );
};

export default Carousel;
