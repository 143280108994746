import axios from "axios";
import React, { useState, useEffect  } from "react";
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { logo } from "../../assets/images";
 
export default function AdminPanel() {
    const naviget = useNavigate();
    const [user, setUser] = useState("");
    const [pass, setPass] = useState("");
    const [error, setError] = useState(""); 
    const [msg, setMsg] = useState("");
 
    useEffect(() => {
        let login = localStorage.getItem("login");
        if(login){
            naviget("/dashboard");
        }
        let loginStatus = localStorage.getItem("loginStatus");
        if(loginStatus){
            setError(loginStatus);
            setTimeout(function(){
                localStorage.clear();
                window.location.reload();
            }, 3000);
        }
        setTimeout(function(){
            setMsg("");
        }, 5000);
    }, [msg]);
 
    const handleInputChange = (e, type) => {
        switch(type){
            case "user":
                setError("");
                setUser(e.target.value);
                if(e.target.value === ""){
                    setError("Username has left blank");
                }
                break;
            case "pass":
                setError("");
                setPass(e.target.value);
                if(e.target.value === ""){
                    setError("Password has left blank");
                }
                break;
            default:
        }
    }

    // const loginSubmit = async () => {
    //     try {
    //       const response = await axios.post('https://www.posstore.az/posstore/login.php', {
    //         user: user,
    //         pass: pass
    //       }, {
    //         headers: {
    //           'Content-Type': 'application/json'
    //         }
    //       });
    //       localStorage.setItem("login", true);
    //       localStorage.setItem('user', user);
    //       naviget("/dashboard");
    //       console.log(response.data);
    //     } catch (error) {
    //       console.error('Error:', error);
    //     }
    //   };



      const loginSubmit = async () => {
        try {
          const response = await axios.post('https://www.tanura.az/tanura/login.php', {
            user: user,
            pass: pass
          }, {
            headers: {
              'Content-Type': 'application/json'
            }
          });
      
          console.log(response.data);
      
          // Gelen yanıtın success olup olmadığını kontrol edin
          if (response.data.success) {
            // Success ise yapılacak işlemler
            localStorage.setItem("login", true);
          localStorage.setItem('user', user);
          naviget("/dashboard");
            // Buraya success durumunda yapılacak diğer işlemleri ekleyebilirsiniz.
          } else {
            console.log("Giriş başarısız:", response.data.result);
            toast.error("Username və ya şifrə yanlışdır");
          }
        } catch (error) {
          console.error('Error:', error);
        }
      };
      
 
   
    return(
        <>
       <section className="min-h-screen flex justify-center items-center">
  <div className="container mx-auto py-12">
    <div className=" bg-white rounded-lg overflow-hidden">
      {/* Left side with image */}

      {/* Right side with form */}
      <div className="w-1/2 flex flex-col justify-center p-8 mx-auto">
        {/* Error/Message Section */}
        <p className="mb-4">
          {
            error !== "" ?
            <div className="text-red-700 font-bold">{error}</div> :
            <div className="text-green-600 font-bold">{msg}</div>
          }
        </p>

        {/* Logo Section */}
        <div className="flex items-center mb-6">
          <span className="text-4xl font-bold">Tanura.az</span>
        </div>

        {/* Sign In Header */}
        <h5 className="text-xl font-medium mb-4">Hesabınıza daxil olun</h5>

        {/* Username Field */}
        <div className="mb-4">
          <label htmlFor="username" className="block text-sm font-medium text-gray-700">User Name</label>
          <input 
            type="text"
            id="username"
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500"
            value={user}
            onChange={(e) => handleInputChange(e, "user")}
          />
        </div>

        {/* Password Field */}
        <div className="mb-4">
          <label htmlFor="pass" className="block text-sm font-medium text-gray-700">Password</label>
          <input 
            type="password"
            id="pass"
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500"
            value={pass}
            onChange={(e) => handleInputChange(e, "pass")}
          />
        </div>

        {/* Submit Button */}
        <div className="mt-6">
          <button 
            className="w-full bg-black text-white py-2 rounded-lg hover:bg-gray-800 transition duration-200"
            onClick={loginSubmit}
          >
            Daxil olun
          </button>
        </div>
      </div>
    </div>
  </div>
</section>

        </>
    )
}