import React from 'react';
import Products from '../../Components/home/Products/Products';
import Carousel from '../../Components/home/Carousel/Carousel'
import About from '../../Components/home/About/About';
import Blogs from '../../Components/home/Blogs/Blogs';
import Brands from '../../Components/home/Brands/Brands';
import Preferences from '../../Components/home/Preferences/Preferences';
import DiscountProducts from '../../Components/home/DiscountProducts/DiscountProducts';
import NewProducts from '../../Components/home/NewProducts/NewProducts';



const Home = () => { 
  return ( 
    <> 
   <div className='bg-[#FCFCFC] pb-[88px] md:pb-[112px]'>
   <Carousel/>
    <Preferences/>
    <About/>
    <NewProducts/>
    <DiscountProducts/>
    <Products/> 
    <Brands/>
   </div>

    
    </>
  )
}

export default Home;
