import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

export default function EditProduct() {
  const navigate = useNavigate();
  const { id } = useParams(); // URL'den id parametresini alıyoruz

  const [product, setProduct] = useState({
    name: '',
    category1: '',
    category2: '',
    category3: '',
    description: '',
    useful: '',
    composition1: '',
    composition2: '',
    composition3: '',
    composition4: '',
    composition5: '',
    composition6: '',
    composition7: '',
    composition8: '',
    composition9: '',
    composition10: '',
    setting1: '',
    answer1: '',
    setting2: '',
    answer2: '',
    setting3: '',
    answer3: '',
    setting4: '',
    answer4: '',
    setting5: '',
    answer5: '',
    bestseller: '',
  });

  const [isLoading, setIsLoading] = useState(true);

  // Sayfa yüklenirken ürünü veritabanından al
  useEffect(() => {
    getProductById();
  }, [id]);

  const getProductById = async () => {
    try {
      const response = await axios.get(
        `https://www.tanura.az/tanura/dashboard.php?id=${id}`
      );
      const fetchedProduct = response.data.data[0]; // İlk ürünü alıyoruz
      setProduct(fetchedProduct);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching product:", error);
    }
  };

  // Input değişikliklerini yönet
  const handleChange = (e) => {
    const { name, value } = e.target;
    setProduct({
      ...product,
      [name]: value,
    });
  };


  // Formu gönderme işlemi
  const handleSubmit = async (event) => {
    event.preventDefault();
  
    const productData = {
      id,
      name: product.name,
    category1: product.category1,
    category2: product.category2,
    category3: product.category3,
    description: product.description,
    useful: product.useful,
    composition1: product.composition1,
    composition2: product.composition2,
    composition3: product.composition3,
    composition4: product.composition4,
    composition5: product.composition5,
    composition6: product.composition6,
    composition7: product.composition7,
    composition8: product.composition8,
    composition9: product.composition9,
    composition10: product.composition10,
    setting1: product.setting1,
    answer1: product.answer1,
    setting2: product.setting2,
    answer2: product.answer2,
    setting3: product.setting3,
    answer3: product.answer3,
    setting4: product.setting4,
    answer4: product.answer4,
    setting5: product.setting5,
    answer5: product.answer5,
    bestseller: product.bestseller,
    };
  
    try {
      const response = await axios.put(
        `https://www.tanura.az/tanura/dashboard.php`,
        productData,  // JSON olarak veri gönderiyoruz
        {
          headers: {
            "Content-Type": "application/json", // JSON veri tipini belirtiyoruz
          },
        }
      );
  
      if (response.data.status === 1) {
        alert("Product updated successfully!");
        navigate(-1);
      } else {
        alert("Error updating product");
      }
    } catch (error) {
      console.error("Error updating product:", error);
      alert("Error updating product");
    }
  };
  
  

  return (
    <div className="max-w-containerSm md:max-w-container mx-auto">
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <form onSubmit={handleSubmit}>
          <table className="bg-[white]" cellSpacing="10">
            <tbody>
              <tr>
                <th><label>Ad</label></th>
                <td>
                  <input
                    type="text"
                    name="name"
                    value={product.name}
                    onChange={handleChange}
                    
                  />
                </td>
              </tr>
              <tr>
                <th><label>Kateqoriya </label></th>
                <td>
                  <select
                    name="category1"
                    value={product.category1}
                    onChange={handleChange}
                    
                  >
                    <option value="set">Set məhsulları</option>
                    <option value="ev">Ev qulluq məhsulları</option>
                    <option value="kabin">Professional baxım məhsulları</option>
                  </select>
                </td>
              </tr>
              <tr>
                <th><label>Alt kateqoriya</label></th>
                <td>
                  <select
                    name="category2"
                    value={product.category2}
                    onChange={handleChange}
                    
                  >
                    <option value="Saç">Saç</option>
                    <option value="Bədən">Bədən</option>
                    <option value="Üz">Üz</option>
                  </select>
                </td>
              </tr>
              <tr>
                <th><label>Dəri tipinə görə</label></th>
                <td>
                  <select
                    name="category3"
                    value={product.category3}
                    onChange={handleChange}
                    
                  >
                    <option value="Bütün">Bütün</option>
                    <option value="Həssas">Həssas</option>
                    <option value="Qarışıq və yağlı">Qarışıq və yağlı</option>
                    <option value="Quru və normal">Quru və normal</option>
                    <option value="Səpkili">Səpkili</option>
                  </select>
                </td>
              </tr>
              <tr>
                <th><label>Təsvir</label></th>
                <td>
                  <input
                    type="text"
                    name="description"
                    value={product.description}
                    onChange={handleChange}
                    
                  />
                </td>
              </tr>
              <tr>
                <th><label>İstifadəsi</label></th>
                <td>
                  <input
                    type="text"
                    name="useful"
                    value={product.useful}
                    onChange={handleChange}
                    
                  />
                </td>
              </tr>
              <tr>
                <th><label>Tərkib 1</label></th>
                <td>
                  <input
                    type="text"
                    name="composition1"
                    value={product.composition1}
                    onChange={handleChange}
                    
                  />
                </td>
              </tr>
              <tr>
                <th><label>Tərkib 2</label></th>
                <td>
                  <input
                    type="text"
                    name="composition2"
                    value={product.composition2}
                    onChange={handleChange}
                    
                  />
                </td>
              </tr>
              <tr>
                <th><label>Tərkib 3</label></th>
                <td>
                  <input
                    type="text"
                    name="composition3"
                    value={product.composition3}
                    onChange={handleChange}
                    
                  />
                </td>
              </tr>
              <tr>
                <th><label>Tərkib 4</label></th>
                <td>
                  <input
                    type="text"
                    name="composition4"
                    value={product.composition4}
                    onChange={handleChange}
                    
                  />
                </td>
              </tr>
              <tr>
                <th><label>Tərkib 5</label></th>
                <td>
                  <input
                    type="text"
                    name="composition5"
                    value={product.composition5}
                    onChange={handleChange}
                    
                  />
                </td>
              </tr>
              <tr>
                <th><label>Tərkib 6</label></th>
                <td>
                  <input
                    type="text"
                    name="composition6"
                    value={product.composition6}
                    onChange={handleChange}
                    
                  />
                </td>
              </tr>
              <tr>
                <th><label>Tərkib 7</label></th>
                <td>
                  <input
                    type="text"
                    name="composition7"
                    value={product.composition7}
                    onChange={handleChange}
                    
                  />
                </td>
              </tr>
              <tr>
                <th><label>Tərkib 8</label></th>
                <td>
                  <input
                    type="text"
                    name="composition8"
                    value={product.composition8}
                    onChange={handleChange}
                    
                  />
                </td>
              </tr>
              <tr>
                <th><label>Tərkib 9</label></th>
                <td>
                  <input
                    type="text"
                    name="composition9"
                    value={product.composition9}
                    onChange={handleChange}
                    
                  />
                </td>
              </tr>
              <tr>
                <th><label>Tərkib 10</label></th>
                <td>
                  <input
                    type="text"
                    name="composition1"
                    value={product.composition10}
                    onChange={handleChange}
                    
                  />
                </td>
              </tr>
              <tr>
                <th><label>Məhsul xüsusiyyəti 1</label></th>
                <td>
                  <input
                    type="text"
                    name="setting1"
                    value={product.setting1}
                    onChange={handleChange}
                    
                  />
                </td>
              </tr>
              <tr>
                <th><label>Cavab 1</label></th>
                <td>
                  <input
                    type="text"
                    name="answer1"
                    value={product.answer1}
                    onChange={handleChange}
                    
                  />
                </td>
              </tr>
              <tr>
                <th><label>Məhsul xüsusiyyəti 2</label></th>
                <td>
                  <input
                    type="text"
                    name="setting2"
                    value={product.setting2}
                    onChange={handleChange}
                    
                  />
                </td>
              </tr>
              <tr>
                <th><label>Cavab 2</label></th>
                <td>
                  <input
                    type="text"
                    name="answer2"
                    value={product.answer2}
                    onChange={handleChange}
                    
                  />
                </td>
              </tr>
              <tr>
                <th><label>Məhsul xüsusiyyəti 3</label></th>
                <td>
                  <input
                    type="text"
                    name="setting3"
                    value={product.setting3}
                    onChange={handleChange}
                    
                  />
                </td>
              </tr>
              <tr>
                <th><label>Cavab 3</label></th>
                <td>
                  <input
                    type="text"
                    name="answer3"
                    value={product.answer3}
                    onChange={handleChange}
                    
                  />
                </td>
              </tr>
              <tr>
                <th><label>Məhsul xüsusiyyəti 4</label></th>
                <td>
                  <input
                    type="text"
                    name="setting4"
                    value={product.setting4}
                    onChange={handleChange}
                    
                  />
                </td>
              </tr>
              <tr>
                <th><label>Cavab 4</label></th>
                <td>
                  <input
                    type="text"
                    name="answer4"
                    value={product.answer4}
                    onChange={handleChange}
                    
                  />
                </td>
              </tr>
              <tr>
                <th><label>Məhsul xüsusiyyəti 5</label></th>
                <td>
                  <input
                    type="text"
                    name="setting5"
                    value={product.setting5}
                    onChange={handleChange}
                    
                  />
                </td>
              </tr>
              <tr>
                <th><label>Cavab 5</label></th>
                <td>
                  <input
                    type="text"
                    name="answer5"
                    value={product.answer5}
                    onChange={handleChange}
                    
                  />
                </td>
              </tr>
              
              <tr>
                <th><label>Ən çox satılan</label></th>
                <td>
                  <select
                    name="bestseller"
                    value={product.bestseller}
                    onChange={handleChange}
                    required
                  >
                    <option value="0">Xeyr</option>
                    <option value="1">Bəli</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td colSpan="2" align="right">
                  <button type="submit">Yadda saxla</button>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      )}
    </div>
  );
}
