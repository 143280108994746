import React from 'react'
import {preferences1, preferences2, preferences3, preferences4 } from '../../../assets/images'

const Preferences = () => {
  return (
    <div className='max-w-containerSm md:max-w-container mx-auto grid md:grid-cols-4 grid-cols-1 text-white mt-[85px] gap-5 md:gap-0'>
  <div className='min-h-[128px] w-full md:w-[305px] rounded-[14px] bg-[#162D59] p-3 flex gap-4'>
 <div className='flex items-center w-[80px]'>
  <img src={preferences1} className='h-[42px] w-[42px] object-cover'></img>
 </div>
 <div>
  <p className='font-bold text-[18px] font-firstFont'>Beynəlxalq Tanınma</p>
  <p className='mt-3 w-[80%] md:w-full text-[14px] font-secondFont'>Candela tərəfindən "Ən Yaxşı Distribütor" mükafatına layiq görülmüşdür</p>
  </div>
  </div>
  <div className='min-h-[128px] w-full md:w-[305px] rounded-[14px] bg-[#162D59] p-3 flex gap-4'>
 <div className='flex items-center w-[80px]'>
  <img src={preferences2} className='h-[42px] w-[42px] object-cover'></img>
 </div>
 <div>
  <p className='font-bold text-[18px] font-firstFont'>Peşəkar Təlimlər</p>
  <p className='mt-3 w-[80%] md:w-full text-[14px] font-secondFont'>Mütəxəssislərimiz beynəlxalq seminar və kurslarda iştirak edirlər.</p>
  </div>
  </div>
  <div className='min-h-[128px] w-full md:w-[305px] rounded-[14px] bg-[#162D59] p-3 flex gap-4'>
 <div className='flex items-center w-[80px]'>
  <img src={preferences3} className='h-[42px] w-[42px] object-cover'></img>
 </div>
 <div>
  <p className='font-bold text-[18px] font-firstFont'>Keyfiyyətli Servis</p>
  <p className='mt-3 w-[80%] md:w-full text-[14px] font-secondFont'>Müştərilərimizə yüksək səviyyədə xidmət və texniki dəstək təqdim edirik.</p>
  </div>
  </div>
  <div className='min-h-[128px] w-full md:w-[305px] rounded-[14px] bg-[#162D59] p-3 flex gap-4'>
 <div className='flex items-center w-[80px]'>
  <img src={preferences4} className='h-[42px] w-[42px] object-cover'></img>
 </div>
 <div>
  <p className='font-bold text-[18px] font-firstFont'>Beynəlxalq Əməkdaşlıq</p>
  <p className='mt-3 w-[80%] md:w-full text-[14px] font-secondFont'>Dünyaca məşhur markalarla seminarlar və master-klasslar təşkil edirik.</p>
  </div>
  </div> 
   </div>
  )
}

export default Preferences