import React from 'react';
import { about1, about3, about4, about5, about6, about7, about8, about9, arashBg, arashBg2 } from '../../assets/images';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation } from 'swiper/modules';

const images = [about5, about5, about5];

const About = () => {
  return (
    <div className='bg-[#FCFCFC]'>
      <div className='max-w-containerSm md:max-w-container mx-auto pb-[88px] md:pb-[112px]'>
        <div className='pt-[56px] text-[14px] text-firstColor flex items-center font-secondFont'>
      <p>Ana səhifə</p>
      <i className={`fas fa-chevron-right mx-2`} /> 
      <p>Haqqımızda</p> 
    </div>
        
        <div className='mt-[48px] md:mt-[56px]'>
          <p className='text-[24px] md:text-[32px] font-bold font-firstFont text-firstColor'>Arash Company</p>
          <p className='mt-[32px] text-firstColor'>
          Arash şirkəti, 1994-cü ildə təsis edilmiş və hazırda Azərbaycanda stomatoloji, kosmetoloji və lazer avadanlıq və məhsullarının satışında liderlik mövqeyindədir. Şirkətimiz, Candela, Ionto Comed, Ultrasun,  Zimmer, Owandy, Digimed, Euronda , DMG , Cavex, Chirana Medical, Septodont,Nexobio, Ajax,  İBSA , Promoitalia, Medytox , Regenyal və sair kimi dünyanın aparıcı istehsalçıları ilə əməkdaşlıq edir. Ümumilikdə  48-dək məşhur  brendin məhsullarının satışı ilə məşğul olan şirkətimiz, kosmetologiya, stomatologiya, dermatologiya və lazer sahələrində yüksək keyfiyyətli və orijinal məhsullar təklif edir.
          </p>
        </div>
        
        <div className='mt-[88px] md:mt-[112px] md:flex justify-between items-center'>
          <p className='text-firstColor font-firstFont text-[24px] md:text-[32px] font-bold md:w-[652px]'>Peşəkar İnkişaf və Beynəlxalq Əməkdaşlıq</p>
          <p className='md:w-[501px] text-[#393941] mt-6 md:mt-0'>
          Arash şirkəti beynəlxalq tanınma qazanaraq, mütəxəssislərinin inkişafına və keyfiyyətli xidmətə önəm verir. Dünyaca məşhur brendlərlə seminarlar və təlimlər təşkil edirik.
          </p>
        </div>
        
        <div className='md:flex justify-between mt-[88px] md:mt-[74px]'>
          <div className='md:w-[643px]'>
            <img src={about3} alt='About' />
          </div>
          <div className='md:w-[575px] mt-[56px] md:mt-0 md:pr-[43px]'>
          <div className='flex gap-4 mt-5'>
                <div className='w-16 h-16'><img className='h-16 w-16' src={about6} alt='Icon' /></div>
                <div className='flex-1'>
                  <p className='text-[18px] md:text-[20px] font-bold text-firstColor font-firstFont'>Beynəlxalq Tanınma və Nailiyyətlər</p>
                  <p className='mt-4 text-secondColor font-secondFont'>
                  Arash şirkəti 2014-cü ildə Candela tərəfindən ən yaxşı distribütor adına layiq görülmüşdür. Şirkət, beynəlxalq sərgilərdə və tədbirlərdə fəal iştirak edərək nüfuzunu daha da möhkəmləndirir.
                  </p>
                </div>
              </div>
              <div className='flex gap-4 mt-5' >
                <div className='w-16 h-16'><img className='h-16 w-16' src={about7} alt='Icon' /></div>
                <div className='flex-1'>
                  <p className='text-[18px] md:text-[20px] font-bold text-firstColor font-firstFont'>Təlimlər və Peşəkar İnkişaf</p>
                  <p className='mt-4 text-secondColor font-secondFont'>
                  Mütəxəssislərimiz beynəlxalq seminarlarda iştirak edərək bilik və bacarıqlarını daim artırırlar. Bu yanaşma, müştərilərimizə yüksək səviyyədə xidmət və dəstək göstərməyimizə imkan yaradır.
                  </p>
                </div>
              </div>
          </div>
        </div>
        
        <div className='flex flex-col-reverse md:flex-row justify-between mt-[88px] md:mt-[74px]'>
          <div className='md:w-[575px] mt-[56px] md:mt-0'>
          <div className='flex gap-4 mt-5' >
                <div className='w-16 h-16'><img className='h-16 w-16' src={about8} alt='Icon' /></div>
                <div className='flex-1'>
                  <p className='text-[18px] md:text-[20px] font-bold text-firstColor font-firstFont'>Mütəxəssis Görüşləri və Təqdimatlar</p>
                  <p className='mt-4 text-secondColor font-secondFont'>
                  Şirkətimiz stomatologiya və estetik tibb sahəsində məşhur xarici mütəxəssislərlə master-klasslar və özəl təlimlər təşkil edir. Bu tədbirlər, iştirakçılara ən son yenilikləri birbaşa sahənin liderlərindən öyrənmək imkanı yaradır.
                  </p>
                </div>
              </div>
              <div className='flex gap-4 mt-5'>
                <div className='w-16 h-16'><img className='h-16 w-16' src={about9} alt='Icon' /></div>
                <div className='flex-1'>
                  <p className='text-[18px] md:text-[20px] font-bold text-firstColor font-firstFont'>Beynəlxalq Brendlərlə Əməkdaşlıq</p>
                  <p className='mt-4 text-[#393941]'>
                  Arash şirkəti Promoitalia, Medytox və IBSA kimi qlobal brendlərlə birgə seminarlar və təqdimatlar təşkil edir. Bu əməkdaşlıqlar, yerli bazara ən yeni və effektiv məhsulları təqdim etməyə şərait yaradır.
                  </p>
                </div>
              </div>
          </div>
          <div className='md:w-[644px]'>
            <img src={about4} alt='About' />
          </div>
        </div>
        
        <div className='bg-[#F1F9FF] rounded-[24px] py-[32px] px-[44px] mt-[88px] md:mt-[112px]'>
        <p className='text-center font-secondFont text-secondColor'>Candela Medical Company, lazer dermatologiyası və kosmetologiya sahəsində şirkətimizlə birgə  həkimlərimiz üçün bir neçə təlimlər keçirib. Mühazirəçi qismində isə dermatologiya sahəsində məşhur mühazirəçilərdən olan Firas Al Niami, Yens Peterson çıxış edib. Həmcinin stomatologiya sahəsində ən məşhur xarici mutəxəssislərlə master-klasslar və ozəl təlimlər təşkil edirik.  <br></br><br></br>
         Tokuyama Dental: Məşhur stomatoloq-ortoped Dr Denis Krutikovun  iştirakı ilə "Kompozit restavrasiyalar. Səhvlər uzərində iş" və "Frontal Dişlərin Estetik Kompozit restavrasiyası" adlı praktiki seminarlarımız kecirilib. Həkim-stomatoloq Yelena Mendosanın iştirakı ilə  "Posterior dişlərin birbaşa estetik-funksional bərpası " adlı seminar praktikum kecirilib. DMG şirkəti: məşhur stomatoloq ortoped dr. Murad Muradov iştirakı ilə "İmplant ustu protezləmə" adlı seminar kecirilib.   Septodont şirkəti:  həkim-stomatoloq Yuriy Vasilyevin iştirakı ilə "Ağrının idarə olunması və ya effektiv yerli anesteziya" adlı seminarımız kecirilib. Arash Company - 28 illik qusursuz xidmət! Bizimlə əməkdaşlıq uğurunuzun təminatıdır.</p>
          <div className='md:w-[910px] gap-5 md:flex mx-auto mt-[56px] hidden'>
            {[about5, about5, about5].map((imgSrc, index) => (
              <img className='w-[290px]' src={imgSrc} alt='Gallery' key={index} />
            ))}
          </div>
          <div className='mt-[56px] block md:hidden'>
             <Swiper
                          spaceBetween={30}
                          loop={true}
                          pagination={{ clickable: true }}
                          navigation={{
                            nextEl: '.swiper-button-next-custom',
                            prevEl: '.swiper-button-prev-custom',
                          }}
                          modules={[Pagination, Navigation]}
                          breakpoints={{
                            480: { slidesPerView: 1 },
                            640: { slidesPerView: 2 },
                            768: { slidesPerView: 3 },
                            1024: { slidesPerView: 4 },
                          }}
                        >
                            {images.map((image, index) => (
                              <SwiperSlide key={index}>
                              <img key={index} className='w-full' src={image} alt={`service-${index + 1}`} />
                              </SwiperSlide>
                            ))}
                        </Swiper>
          </div>
        </div>
        
        <div
  className="rounded-[20px] py-[40px] md:py-[60px] text-white mt-[88px] md:mt-[112px] bg-cover bg-center h-[310px] hidden md:block"
  style={{ backgroundImage: `url(${arashBg})` }}
>
  <div className="md:w-[914px] w-[275px] mx-auto">
    <p className="text-[24px] md:text-[32px] font-bold text-center font-firstFont">
      Arash – keyfiyyət, innovasiya və etibarlılığın ünvanı!
    </p>
    <p className="mt-5 text-[14px] md:text-[16px] text-center font-secondFont">
      Biz keyfiyyətə olan sadiqliyimiz və yüksək peşəkarlığımız ilə sektorda
      liderliyimizi davam etdiririk, müştərilərimizə ən son texnologiyalarla
      ən yaxşı həlləri təqdim etməyə, onların ehtiyaclarına uyğun yeniliklər və
      təkmilləşdirmələr təklif etməyə, eyni zamanda sahədəki inkişafları
      izləyərək hər zaman mükəmməl xidmət və məhsul təqdim etməyə çalışırıq.
    </p>
  </div>
</div>
<div
  className="rounded-[20px] py-[60px] text-white mt-[88px] md:mt-[112px] bg-cover bg-center h-[480px] block md:hidden"
  style={{ backgroundImage: `url(${arashBg2})` }}
>
  <div className="md:w-[914px] w-[275px] mx-auto">
    <p className="text-[24px] md:text-[32px] font-bold text-center font-firstFont">
      Arash – keyfiyyət, innovasiya və etibarlılığın ünvanı!
    </p>
    <p className="mt-5 text-[14px] md:text-[16px] text-center font-secondFont">
      Biz keyfiyyətə olan sadiqliyimiz və yüksək peşəkarlığımız ilə sektorda
      liderliyimizi davam etdiririk, müştərilərimizə ən son texnologiyalarla
      ən yaxşı həlləri təqdim etməyə, onların ehtiyaclarına uyğun yeniliklər və
      təkmilləşdirmələr təklif etməyə, eyni zamanda sahədəki inkişafları
      izləyərək hər zaman mükəmməl xidmət və məhsul təqdim etməyə çalışırıq.
    </p>
  </div>
</div>

      </div>
    </div>
  ); 
};

export default About;
