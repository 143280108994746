import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom"; // React Router importları
import { aboveHeader1, aboveHeader2, aboveHeader3, aboveHeader4, aboveHeader5, aboveHeader6, aboveHeader7, aboveHeader8, aboveHeader9 } from '../../assets/images'
import axios from "axios";


const AboveHeader = () => {

    const location = useLocation();
      const [item, setItem] = useState([]);
        const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      getProducts();
    }, []);
  
    const getProducts = async () => {
      try {
        const response = await axios.get(
          `https://www.adb.az/arash/settings.php`,
          { withCredentials: false }
        );
        console.log(response.data.data);
        setItem(response.data.data[0]);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

  return (
   <div className='border-b-[1px] border-[#CFCFCF] hidden md:block font-secondFont'>
    <div className='max-w-container mx-auto flex justify-between py-2'>
  <div className='flex gap-[32px]'> 
  <div className='flex gap-[10px]'> 
  <img className="h-[18px] w-[18px]" src={aboveHeader1}></img>
  <p className='text-[14px] text-[#0B0B0B]'>{item.phone}</p>
  </div>
  <div className='flex gap-[10px]'>
  <img className="h-[18px] w-[18px]" src={aboveHeader2}></img>
  <p className='text-[14px] text-[#0B0B0B]'>{item.mobile}</p>
  </div>
  <div className='flex gap-[10px]'>
  <img className="h-[18px] w-[18px]" src={aboveHeader3}></img>
  <p className='text-[14px] text-[#0B0B0B]'>{item.hours}</p>
  </div>
  <div className='flex gap-[10px]'>
  <img className="h-[18px] w-[18px]" src={aboveHeader4}></img>
  <p className='text-[14px] text-[#0B0B0B]'>{item.location}</p>
  </div>
  </div>
  <div className='flex gap-3'>
  <a target='_blank' href={item.instagram}><img className='w-6 h-6' src={aboveHeader5}></img></a>
  <a target='_blank' href={item.youtube}><img className='w-6 h-6' src={aboveHeader6}></img></a>
  <a target='_blank' href={item.facebook}><img className='w-6 h-6' src={aboveHeader7}></img></a>
  <a target='_blank' href={item.linkedin}><img className='w-6 h-6' src={aboveHeader8}></img></a>
  <a target='_blank' href={item.tiktok}><img className='w-6 h-6' src={aboveHeader9}></img></a>
  </div>
   </div>
   </div>
  )
}

export default AboveHeader