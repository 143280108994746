import React, { useEffect, useState } from "react";
import { about1, logo, about2, about3, footer1, footer2, footer3, footer4, footer5, footer6, footer7, footer8, footer9, footer10, footer, footerMobile } from '../../assets/images';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from "react-router-dom"; // React Router importları
import axios from "axios";

const Footer = () => {

  const { t, i18n } = useTranslation();

   const location = useLocation();
        const [item, setItem] = useState([]);
          const [isLoading, setIsLoading] = useState(true);
  
      useEffect(() => {
        getProducts();
      }, []);
    
      const getProducts = async () => {
        try {
          const response = await axios.get(
            `https://www.adb.az/arash/settings.php`,
            { withCredentials: false }
          );
          console.log(response.data.data);
          setItem(response.data.data[0]);
          setIsLoading(false);
        } catch (error) {
          console.error("Error fetching products:", error);
        }
      };

  return (
   <div>
     <footer className='hidden md:block' style={{
        backgroundImage: `url(${footer})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}>
      <div className="max-w-containerSm md:max-w-container mx-auto md:flex justify-between py-[50px] md:py-[80px]">
        <div className='md:w-[323px]'>
         <img className='w-[92px]' src={logo}></img>
          <p className='text-[17px] mt-[20px] md:mt-[40px] font-thirdFont'>{t("Lazer, stomotoloji və kosmetoloji məhsulların satışı və servisi")}</p>
<div className='flex gap-3 mt-[20px] md:mt-[40px]'>
  <div>
  <a target='_blank' href={item.instagram}><img className='w-10 h-10' src={footer1}></img></a>
  </div>
  <div>
  <a target='_blank' href={item.youtube}><img className='w-10 h-10' src={footer2}></img></a>
  </div> 
  <div>
  <a target='_blank' href={item.facebook}><img className='w-10 h-10' src={footer3}></img></a>
  </div>
  <div>
  <a target='_blank' href={item.linkedin}><img className='w-10 h-10' src={footer4}></img></a>
  </div>
  <div>
  <a target='_blank' href={item.tiktok}><img className='w-10 h-10' src={footer5}></img></a>
  </div>
</div>
        </div>
        <div className='md:w-[240px] mt-10 md:mt-0 font-secondFont'>
          <h4 className="font-semibold text-[black] font-firstFont">{t("Sürətli keçid")}</h4>
          <div className="mt-5 bg-[#377BFA] h-[1px]"></div>
          <ul className='space-y-2 md:space-y-4 mt-5'>
            <li><a href="/products" className="hover:underline"> <i className={`fas fa-chevron-right text-[black] mr-3`}
              />{t("Məhsullar")}</a></li>
                        <li><a href="/service" className="hover:underline"> <i className={`fas fa-chevron-right text-[black] mr-3`}
              />{t("Servis")}</a></li>
                          <li><a href="/kampaniya" className="hover:underline"> <i className={`fas fa-chevron-right text-[black] mr-3`}
              />{t("Kampaniya")}</a></li>
                                        <li><a href="/kredit" className="hover:underline"> <i className={`fas fa-chevron-right text-[black] mr-3`}
              />{t("Kredit")}</a></li>
                                        <li><a href="/icare" className="hover:underline"> <i className={`fas fa-chevron-right text-[black] mr-3`}
              />{t("İcarə")}</a></li>
          </ul>
        </div>
        <div className='md:w-[240px] mt-10 md:mt-0 font-secondFont'>
          <h4 className="font-semibold text-[black] font-firstFont">{t("Məlumat")}</h4>
          <div className="mt-5 bg-[#377BFA] h-[1px]"></div>
          <ul className='space-y-2 md:space-y-4 mt-5'>
            <li><a href="/about" className="hover:underline"> <i className={`fas fa-chevron-right text-[black] mr-3`}
              />{t("Haqqımızda")}</a></li>
                        <li><a href="/blogs" className="hover:underline"> <i className={`fas fa-chevron-right text-[black] mr-3`}
              />{t("Bloq")}</a></li>
                          <li><a href="/contact" className="hover:underline"> <i className={`fas fa-chevron-right text-[black] mr-3`}
              />{t("Əlaqə")}</a></li>
                                        <li><a href="/products?categories=Kosmetoloji+avadanlıq" className="hover:underline"> <i className={`fas fa-chevron-right text-[black] mr-3`}
              />{t("Kosmetoloji avadanlıq")}</a></li>
                                        <li><a href="/products?categories=Lazer+avadanlığı" className="hover:underline"> <i className={`fas fa-chevron-right text-[black] mr-3`}
              />{t("Lazer avadanlığı")}</a></li>
          </ul>
        </div>
        <div className='md:w-[275px] mt-10 md:mt-0 text-[#1B1919] font-secondFont'>
          <h4 className="font-semibold text-[#0B0B0B] font-firstFont">{t("Əlaqə")}</h4>
          <div className="mt-5 bg-[#377BFA] h-[1px]"></div>
          <ul className='space-y-2 md:space-y-4 mt-5'>
            <li><a className="hover:underline flex"> <img className='h-6 w-6 mr-2' src={footer6}></img>Ünvan: {item.location}</a></li>
            <li><a className="hover:underline flex"> <img className='h-6 w-6 mr-2' src={footer7}></img>Poçt indeksi: {item.postal}</a></li>
            <li><a  className="hover:underline flex"> <img className='h-6 w-6 mr-2' src={footer8}></img>Telefon: {item.phone}</a></li>
            <li><a className="hover:underline flex"> <img className='h-6 w-6 mr-2' src={footer9}></img>Mobil: {item.mobile}</a></li>
            <li><a className="hover:underline flex"> <img className='h-6 w-6 mr-2' src={footer10}></img>E-mail: {item.email}</a></li>
          </ul>
        </div>
      </div>
      <div className='bg-[#377BFA] h-[1px] max-w-containerSm md:max-w-container mx-auto'></div>
      {/* <div className="max-w-containerSm md:max-w-container md:flex justify-between mx-auto py-4 text-sm">
        <p>Arash.az © {t("Bütün hüquqlar qorunur.")}</p>
        <a href='https://www.birsayt.az/'><p>{t("Sayt hazırlandı")}: Birsayt.az</p></a>
      </div> */}
    </footer>
       <footer className='block md:hidden'  style={{
        backgroundImage: `url(${footerMobile})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}>
      <div className="max-w-containerSm md:max-w-container mx-auto md:flex justify-between py-[50px] md:py-[80px]">
        <div className='md:w-[323px]'>
         <img className='w-[92px]' src={logo}></img>
          <p className='text-[17px] mt-[20px] md:mt-[40px] font-thirdFont'>{t("Lazer, stomotoloji və kosmetoloji məhsulların satışı və servisi")}</p>
<div className='flex gap-3 mt-[20px] md:mt-[40px]'>
  <div>
  <a  target='_blank' href={item.instagram}><img className='w-10 h-10' src={footer1}></img></a>
  </div>
  <div>
  <a target='_blank' href={item.youtube}><img className='w-10 h-10' src={footer2}></img></a>
  </div>
  <div>
  <a target='_blank' href={item.facebook}><img className='w-10 h-10' src={footer3}></img></a>
  </div>
  <div>
  <a target='_blank' href={item.linkedin}><img className='w-10 h-10' src={footer4}></img></a>
  </div>
  <div>
  <a target='_blank' href={item.tiktok}><img className='w-10 h-10' src={footer5}></img></a>
  </div>
</div>
        </div>
        <div className='md:w-[240px] mt-10 md:mt-0 font-secondFont'>
          <h4 className="font-semibold text-[black] font-firstFont">{t("Sürətli keçid")}</h4>
          <div className="mt-5 bg-[#377BFA] h-[1px]"></div>
          <ul className='space-y-2 md:space-y-4 mt-5'>
            <li><a href="/products" className="hover:underline"> <i className={`fas fa-chevron-right text-[black] mr-3`}
              />{t("Məhsullar")}</a></li>
                        <li><a href="/service" className="hover:underline"> <i className={`fas fa-chevron-right text-[black] mr-3`}
              />{t("Servis")}</a></li>
                          <li><a href="/kampaniya" className="hover:underline"> <i className={`fas fa-chevron-right text-[black] mr-3`}
              />{t("Kampaniya")}</a></li>
                                        <li><a href="/kredit" className="hover:underline"> <i className={`fas fa-chevron-right text-[black] mr-3`}
              />{t("Kredit")}</a></li>
                                        <li><a href="/icare" className="hover:underline"> <i className={`fas fa-chevron-right text-[black] mr-3`}
              />{t("İcarə")}</a></li>
          </ul>
        </div>
        <div className='md:w-[240px] mt-10 md:mt-0 font-secondFont'>
          <h4 className="font-semibold text-[black] font-firstFont">{t("Məlumat")}</h4>
          <div className="mt-5 bg-[#377BFA] h-[1px]"></div>
          <ul className='space-y-2 md:space-y-4 mt-5'>
            <li><a href="/about" className="hover:underline"> <i className={`fas fa-chevron-right text-[black] mr-3`}
              />{t("Haqqımızda")}</a></li>
                        <li><a href="/blogs" className="hover:underline"> <i className={`fas fa-chevron-right text-[black] mr-3`}
              />{t("Bloq")}</a></li>
                          <li><a href="/contact" className="hover:underline"> <i className={`fas fa-chevron-right text-[black] mr-3`}
              />{t("Əlaqə")}</a></li>
                                        <li><a href="/products" className="hover:underline"> <i className={`fas fa-chevron-right text-[black] mr-3`}
              />{t("Kosmetoloji avadanlıq")}</a></li>
                                        <li><a href="/products" className="hover:underline"> <i className={`fas fa-chevron-right text-[black] mr-3`}
              />{t("Lazer avadanlığı")}</a></li>
          </ul>
        </div>
        <div className='md:w-[275px] mt-10 md:mt-0 text-[#1B1919] font-secondFont'>
          <h4 className="font-semibold text-[#0B0B0B] font-firstFont">{t("Əlaqə")}</h4>
          <div className="mt-5 bg-[#377BFA] h-[1px]"></div>
          <ul className='space-y-2 md:space-y-4 mt-5'>
            <li><a className="hover:underline flex"> <img className='h-6 w-6 mr-2' src={footer6}></img>Ünvan: {item.location}</a></li>
            <li><a className="hover:underline flex"> <img className='h-6 w-6 mr-2' src={footer7}></img>Poçt indeksi: {item.postal}</a></li>
            <li><a className="hover:underline flex"> <img className='h-6 w-6 mr-2' src={footer8}></img>Telefon: {item.phone}</a></li>
            <li><a className="hover:underline flex"> <img className='h-6 w-6 mr-2' src={footer9}></img>Mobil: {item.mobile}</a></li>
            <li><a  className="hover:underline flex"> <img className='h-6 w-6 mr-2' src={footer10}></img>E-mail: {item.email}</a></li>
          </ul>
        </div>
      </div>
      <div className='bg-[#377BFA] h-[1px] max-w-containerSm md:max-w-container mx-auto'></div>
      {/* <div className="max-w-containerSm md:max-w-container md:flex justify-between mx-auto py-4 text-sm">
        <p>Arash.az © {t("Bütün hüquqlar qorunur.")}</p>
        <a target='_blank' href='https://www.birsayt.az/'><p>{t("Sayt hazırlandı")}: Birsayt.az</p></a>
      </div> */}
    </footer>
   </div>
  );
}; 

export default Footer;