import React, { useEffect, useState } from 'react';
import { about2 } from '../../assets/images';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import axios from 'axios';

const KampaniyaDetail = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [kampaniyalar, setKampaniyalar] = useState([]);
  const [single, setSingle] = useState(null);
  const [others, setOthers] = useState([]);

  useEffect(() => {
    const getProducts = async () => {
      try {
        const response = await axios.get('https://www.adb.az/arash/kampaniya.php');
        const data = response.data.data || [];

        setKampaniyalar(data);

        const current = data.find(item => item.id == id);
        setSingle(current);

        const otherItems = data.filter(item => item.id != id).slice(0, 3);
        setOthers(otherItems);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    getProducts();
  }, [id]);

  if (!single) return null;

  const KampaniyaCard = ({ item }) => (
    <div>
      <img className='h-[248px] w-full' src={`https://adb.az/arash/${item.image}`} alt={item.title} />
      <p className='text-[#A3A3A3] text-[14px] font-secondFont mt-2'>{item.date}</p>
      <p className='text-firstColor text-[18px] font-bold font-firstFont mt-2'>{item.title}</p>
      <p className='text-[#696868] font-secondFont mt-2'>{item.content.slice(0, 50)}...</p>
      <a href={`/kampaniya/${item.id}`}>
        <div className='flex justify-between items-center mt-2 px-12 text-[#162D59] w-[211px] h-[44px] mx-auto font-secondFont'>
          <p className='mr-10'>Ətraflı</p>
          <i className="fas fa-chevron-right text-[#162D59]" />
        </div>
      </a>
    </div>
  );

  return (
    <div className='bg-[#FCFCFC]'>
      <div className='max-w-containerSm md:max-w-container mx-auto pb-[88px] md:pb-[112px]'>
        
        {/* Breadcrumb */}
        <div className='pt-[56px] text-[14px] text-firstColor flex items-center font-secondFont'>
          <p>Ana səhifə</p>
          <i className='fas fa-chevron-right mx-2' />
          <p>Kampaniya</p>
          <i className='fas fa-chevron-right mx-2' />
          <p>{single?.title}</p>
        </div>

        {/* Detail Section */}
        <div className='mt-[56px] md:flex justify-between'>
          <div className='md:w-[649px]'>
            <img className='w-full' src={`https://adb.az/arash/${single.image}`} alt={single.title} />
          </div>
          <div className='md:w-[513px] mt-[48px] md:mt-0'>
            <p className='text-[24px] font-bold text-firstColor font-firstFont'>{single.title}</p>
            <p className='text-secondColor font-secondFont mt-[21px]'>{single.date}</p>
            <p className='text-firstColor font-secondFont mt-[30px]'>{single.content}</p>
            <div className='flex justify-end'>
              <a className='md:w-[203px] w-full' href='/contact'>
                <button className="w-full h-[44px] rounded-2xl bg-[#2264DC] text-white mt-[48px] font-secondFont">Müraciət et</button>
              </a>
            </div>
          </div>
        </div>

        {/* Other Campaigns */}
        <div className='mt-[88px] md:mt-[120px]'>
          <div className='flex md:justify-between justify-center'>
            <p className="text-[24px] font-bold text-center md:text-left font-firstFont text-firstColor">{t("Digər kampaniyalar")}</p>
            <a className='hidden md:block' href='/kampaniya'>
              <div className='flex justify-between items-center rounded-[16px] font-secondFont bg-[#2264DC] text-white w-[203px] h-[44px] px-[20px]'>
                <p>Hamısına bax</p>
                <img className='h-6 w-6' src={about2} alt='icon' />
              </div>
            </a>
          </div>

          {/* Desktop Grid */}
          <div className='md:grid grid-cols-1 md:grid-cols-3 mt-[48px] md:mt-[34px] gap-5 hidden'>
            {others.map((item, index) => (
              <KampaniyaCard key={index} item={item} />
            ))}
          </div>

          {/* Mobile Slider */}
          <div className='block md:hidden mt-[48px]'>
            <Swiper
              slidesPerView={1}
              spaceBetween={20}
              loop={true}
              autoplay={{ delay: 4000, disableOnInteraction: false }}
              pagination={{ clickable: true }}
              modules={[Autoplay, Pagination, Navigation]}
              className="mySwiper"
            >
              {others.map((item, index) => (
                <SwiperSlide key={index}>
                  <KampaniyaCard item={item} />
                </SwiperSlide>
              ))}
            </Swiper>

            <a className='block mt-[48px]' href='/kampaniya'>
              <div className='flex justify-between items-center rounded-[16px] bg-[#2264DC] font-secondFont text-white w-full h-[44px] px-[20px]'>
                <p>Hamısına bax</p>
                <img className='h-6 w-6' src={about2} alt='icon' />
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KampaniyaDetail;
