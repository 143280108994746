import React, { useState, useEffect } from 'react'
import { about1, about3, about4, about5, about6, about7, about8, about9, carousel1, carousel2 } from '../../assets/images'
import axios from 'axios';

 
 const Blogs = () => {


      const [list, setItems] = useState([]);
      const [isLoading, setIsLoading] = useState(true);
          const [visibleCount, setVisibleCount] = useState(3); // Başlangıçta 3 kampaniya göster

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    try {
      const response = await axios.get(
        `https://www.adb.az/arash/blogs.php`,
        { withCredentials: false }
      );
   console.log(response.data.data)
      setItems(response.data.data); 
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const loadMore = () => {
    setVisibleCount((prevCount) => prevCount + 3); // 3'er 3'er artır
  };


  return ( 
   <div className='bg-[#FCFCFC]'>
    <div className='max-w-containerSm md:max-w-container mx-auto pb-[88px] md:pb-[112px]'>
     <div className='pt-[56px] text-[14px] text-firstColor flex items-center font-secondFont'>
      <p>Ana səhifə</p>
      <i className={`fas fa-chevron-right mx-2`} /> 
      <p>Bloq</p>
    </div>
    <div className='mt-[48px] md:mt-[56px] grid grid-cols-1 md:grid-cols-3 gap-4 md:hidden'>
       {list?.slice(0, visibleCount).map((slide, index) => (
                    <a href={`/blogs/${slide.id}`}>
                       <div className='min-h-[510px] px-[25px] py-[32px] border-[1px] border-[#CFCFCF] rounded-[24px]'>
                     <img className='w-full'  src={`https://adb.az/arash/${slide.image}`}></img>
                     <p className='text-secondColor mt-5 font-secondFont'>{slide.date}</p>
                     <p className='mt-5 text-[#393941] text-[20px] font-bold font-firstFont'>{slide.title}</p>
                     <p className='mt-2 text-[#393941] font-secondFont'>{slide.content?.slice(0, 50)}...</p>
                   </div>
                    </a>
              ))}
    </div>
    <div className='mt-[48px] md:mt-[56px] md:grid grid-cols-1 md:grid-cols-3 gap-4 hidden'>
       {list?.map((slide, index) => (
                    <a className='group' href={`/blogs/${slide.id}`}>
                       <div className='min-h-[510px] px-[25px] py-[32px] border-[1px] border-[#CFCFCF] rounded-[24px]  group-hover:bg-[#F1F9FF]'>
                     <img className='w-full'  src={`https://adb.az/arash/${slide.image}`}></img>
                     <p className='text-secondColor mt-5 font-secondFont'>{slide.date}</p>
                     <p className='mt-5 text-[#393941] text-[20px] font-bold font-firstFont group-hover:text-[#2264DC]'>{slide.title}</p>
                     <p className='mt-2 text-[#393941] font-secondFont'>{slide.content?.slice(0, 50)}...</p>
                   </div>
                    </a>
              ))}
    </div>
    {visibleCount < list.length && (
          <div className='md:hidden flex justify-center '>
            <button
              onClick={loadMore} 
              className="md:w-[203px] w-full h-[44px] rounded-2xl bg-[#2264DC] text-white mt-[40px] md:mt-[64px]"
            >
              Daha çox 
            </button> 
          </div>
        )}
   </div>
   </div>
  )
}
 
export default Blogs