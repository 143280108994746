import React, { useEffect, useState } from 'react';
import { about2 } from '../../assets/images';
import { useParams } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import axios from 'axios';

const BlogCard = ({ slide }) => (
  <a href={`/blogs/${slide.id}`} key={slide.id}>
    <div className='min-h-[510px] px-[25px] py-[32px] border border-[#CFCFCF] rounded-[24px]'>
      <img className='w-full' src={`https://adb.az/arash/${slide.image}`} alt={slide.title} />
      <p className='text-[#393941] mt-5'>{slide.date}</p>
      <p className='mt-5 text-[#393941] text-[20px] font-bold'>{slide.title}</p>
      <p className='mt-2 text-[#393941]'>{slide.content?.slice(0, 50)}...</p>
    </div>
  </a>
);

const BlogDetail = () => {
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [others, setOthers] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    const getProducts = async () => {
      try {
        const response = await axios.get('https://www.adb.az/arash/blogs.php');
        const data = response.data.data || [];
        setList(data);
        const filteredOthers = data.filter((item) => item.id !== id).slice(0, 3);
        setOthers(filteredOthers);
      } catch (error) {
        console.error('Error fetching products:', error);
      } finally {
        setIsLoading(false);
      }
    };

    getProducts();
  }, [id]);

  const filteredProduct = list.find((product) => product.id == id);

  if (isLoading) {
    return <div className="text-center py-20">Yüklənir...</div>;
  }

  return (
    <div className='bg-[#FCFCFC]'>
      <div className='max-w-containerSm md:max-w-container mx-auto pb-[88px] md:pb-[112px]'>
        {/* Breadcrumb */}
        <div className='pt-[56px] text-[14px] text-firstColor flex items-center font-secondFont'>
          <p>Ana səhifə</p>
          <i className='fas fa-chevron-right mx-2' />
          <p>Bloq</p>
          <i className='fas fa-chevron-right mx-2' />
          <p>{filteredProduct?.title}</p>
        </div>

        {/* Blog Detail */}
        <div className='mt-[48px] md:mt-[56px] flex flex-col-reverse md:flex-row justify-between'>
          <div className='md:w-[565px] mt-5 md:mt-0'>
            <p className='text-[24px] font-bold text-[#0B0B0B] font-firstFont'>{filteredProduct?.title}</p>
            <p className='text-[#393941] mt-5 font-secondFont'>{filteredProduct?.date}</p>
            <p className='text-[#0B0B0B] mt-7 font-firstFont'>{filteredProduct?.content}</p>
          </div>
          <div className='md:w-[649px]'>
            <img className='w-full' src={`https://adb.az/arash/${filteredProduct?.image}`} alt={filteredProduct?.title} />
          </div>
        </div>

        {/* Other Blogs */}
        <div className='mt-[88px] md:mt-[120px]'>
          <div className='flex md:justify-between justify-center'>
            <p className='text-[24px] font-bold text-center text-firstColor md:text-left font-firstFont'>Digər bloqlar</p>
            <a className='hidden md:block' href='/blogs'>
              <div className='flex items-center rounded-[16px] bg-[#2264DC] text-white font-secondFont w-[203px] h-[44px] px-[20px]'>
                <p>Hamısına bax</p>
                <img className='h-6 w-6' src={about2} alt="arrow" />
              </div>
            </a>
          </div>

          {/* Desktop Grid */}
          <div className='hidden md:grid grid-cols-3 gap-5 mt-[34px]'>
            {others.map((slide) => <BlogCard key={slide.id} slide={slide} />)}
          </div>

          {/* Mobile Swiper */}
          <div className='block md:hidden mt-[48px]'>
            <Swiper
              slidesPerView={1}
              spaceBetween={20}
              loop={true}
              autoplay={{
                delay: 4000,
                disableOnInteraction: false,
              }}
              pagination={{ clickable: true }}
              modules={[Autoplay, Pagination, Navigation]}
              className="mySwiper"
            >
              {others.map((slide) => (
                <SwiperSlide key={slide.id}>
                  <BlogCard slide={slide} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>

          {/* Mobile "Hamısına bax" Button */}
          <a className='block md:hidden' href='/blogs'>
            <div className='flex items-center rounded-[16px] bg-[#2264DC] text-white w-full mt-[48px] h-[44px] px-[20px] font-secondFont'>
              <p>Hamısına bax</p>
              <img className='h-6 w-6' src={about2} alt="arrow" />
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default BlogDetail;
