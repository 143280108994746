import React, { useEffect, useState } from 'react';
import { logo } from '../../assets/images';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useTranslation, initReactI18next } from "react-i18next";
import i18n from "i18next";
import enTranslation from "../i18n/en/translation.json";
import azTranslation from "../i18n/az/translation.json";
import rusTranslation from "../i18n/rus/translation.json";
import { NavLink } from "react-router-dom";



const languageOptions = [
  { value: "en", label: "EN", },
  { value: "az", label: "AZ",  },
  { value: "rus", label: "RU", },
];

i18n.use(initReactI18next).init({
  resources: {
      en: { translation: enTranslation }, 
      az: { translation: azTranslation },
      rus: { translation: rusTranslation },
  },
  lng: localStorage.getItem("language") || "az",
  fallbackLng: "az",
  interpolation: { escapeValue: false },
});

const Header = () => {

  const navigate = useNavigate();

  function logoutSubmit() {
    localStorage.setItem("login", "");
    localStorage.setItem("loginStatus", "Logged out successfully!");
    navigate("/");
  }



  const location = useLocation(); // Get current route

  // Function to check if the current path is active
  const isActiveLink = (path) => location.pathname === path;

  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);
  const toggleHamburgerMenu = () => setIsHamburgerMenuOpen(!isHamburgerMenuOpen);




  return (
    <>
        <div className='max-w-containerSm md:max-w-container mx-auto h-[40px] flex justify-between my-6'>
        <button className="md:hidden focus:outline-none" onClick={toggleHamburgerMenu}>
        <i className="fa-solid fa-bars text-[24px] text-[black]"></i>
        {isHamburgerMenuOpen && (
        <div className={`fixed inset-y-0  bg-[white] left-0 transform ${isHamburgerMenuOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out w-full z-50 shadow-lg`}>
                 <div className="flex">

          <button className="absolute top-5 right-5 p-2 rounded" onClick={toggleHamburgerMenu}>
          <i className="fa-solid fa-xmark text-[32px] text-[black]"></i>
          </button>
          
                 </div>
          <div className="flex flex-col items-start p-8 w-full ">
            <div className="items-center justify-center sm:justify-end space-x-2 space-y-2 sm:space-y-0 h-screen">
            <div className="gap-4 max-w-containerSm flex flex-col"> 
       <a href='/'> <img src={logo} alt="Logo" className="w-[109px] mb-5" /></a>
              <a href="/dashboard" className={`text-base text-left font-normal ${isActiveLink('/products') ? 'text-[#223077]' : 'text-[black]'}`}>Məhsullar</a>
              <a href="/dashboard/contact" className={`text-base text-left font-normal ${isActiveLink('/contact') ? 'text-[#223077]' : 'text-[black]'}`}>Əlaqə</a>
              <a href="/dashboard/orders" className={`text-base text-left font-normal ${isActiveLink('/orders') ? 'text-[#223077]' : 'text-[black]'}`}>Sifarişlər</a>
    </div>

          </div>
          </div>
        </div>
      )} 
      </button>
          <div className='md:flex items-center w-[580px] hidden'>
          <nav>
  <ul className="flex justify-between text-[black]">
    <a className={`mr-[23px] ${isActiveLink('/dashboard') ? 'text-[#223077] ' : 'text-[black]'}`} href="/dashboard">
      <li>Məhsullar</li>
    </a>

    <a className={`mr-[23px] ${isActiveLink('/dashboard/contact') ? 'text-[#223077] ' : 'text-[black]'}`}  href="/dashboard/contact">
      <li>Əlaqə</li>
    </a>

    <a className={`mr-[23px] ${isActiveLink('/dashboard/orders') ? 'text-[#223077] ' : 'text-[black]'}`}  href="/dashboard/orders">
      <li>Sifarişlər</li>
    </a>
    <a className={`${isActiveLink('/dashboard/photos') ? 'text-[#223077] ' : 'text-[black]'}`}  href="/dashboard/photos">
      <li>Şəkillər</li>
    </a>
  </ul>
</nav>

          </div>
          <div className="d-flex">
          <button  
            type="submit"
            onClick={logoutSubmit} className=" bg-[#223077] text-white px-6 sm:px-7 md:px-8 py-2 rounded-full">Çıxış</button>
        </div>
      </div>
    </>
  );
};

export default Header;










