import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
// import { paginationItems } from "../../../constants";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import DashboardHeader from "../../Components/DashboardHeader/DashboardHeader";
 
// const items = paginationItems

const Pagination = () => {
  const { category1 } = useParams(); 
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // loading durumu eklendi


  const {brand } = useParams();
  console.log(category1)

  useEffect(() => { 
    getProducts();
  }, [category1]);

  const getProducts = async () => {
    try {
      const response = await axios.get(
        `https://www.tanura.az/tanura/dashboard.php`,
        { withCredentials: false }
      );
  
      //  console.log(response.data.data);

        const filteredProducts = response.data.data.filter(product => product.category1 == category1);
        setItems(filteredProducts);
        console.log(filteredProducts)
        setIsLoading(false)
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };



  const deleteUser = async (id) => {
    console.log("Deleting user with ID:", id); // Log the id to ensure it's correct
    try {
      const response = await axios.delete(`https://www.tanura.az/tanura/dashboard.php?id=${id}`);
      console.log(response.data);
      getProducts()
    } catch (error) {
      console.error("Failed to delete user:", error.message);
    }
  };
  
  
  
  
  


  


  return (
      <>
      <DashboardHeader/>
        <div className="max-w-containerSm md:max-w-container mx-auto py-20">
          <table className="bg-[white]"> 
            <thead>
              <tr>
              <th>Actions</th>
                <th>ID</th>
                <th>Ad</th>
                <th>Kateqoriya</th>
                <th>Alt Kateqoriya</th>
                <th>Dəri tipinə görə</th>
                <th>Ən çox satılan</th>
              </tr>
            </thead>
            <tbody>
              {items.map((user, key) => (
                <tr key={key}>
                  <td>
                    <Link
                      to={`/dashboard/${user.id}/edit`}
                      style={{ marginRight: "10px" }}
                    >
                      Yenilə
                    </Link>
                    <button onClick={() => deleteUser(user.id)}>Sil</button>
                  </td>
                  <td>{user.id}</td>
                  <td>{user.name}</td>
                  <td>{user.category1}</td>
                  <td>{user.category2}</td>
                  <td>{user.category3}</td>
                  <td>{user.bestseller}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>

  );
};

export default Pagination;