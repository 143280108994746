import axios from "axios";
import React, { useEffect, useState } from "react";
import "./dashboard.css";
import { Link, useNavigate } from "react-router-dom";
import DashboardHeader from "../../Components/DashboardHeader/DashboardHeader";

export default function DashboardOrders() {
  
  const [products, setProducts] = useState([]);
  useEffect(() => {
    getProducts();
  }, []);

  function getProducts() {
    axios
      .get("https://www.tanura.az/tanura/orders.php/", {
        withCredentials: false
    })
      .then(function (response) {
        setProducts(response.data);
      });
  }

  const deleteUser = (id) => {
    axios
      .delete(`https://www.tanura.az/tanura/orders.php/${id}/delete`, {
        withCredentials: false
    })
      .then(function (response) {
        getProducts();
      });
  };
  return (
    <>
    <DashboardHeader/>
    <div className="max-w-containerSm md:max-w-container mx-auto py-20">
          <table className="bg-[white]">
            <thead>
              <tr>
              <th>Actions</th>
                <th>ID</th>
                <th>Məhsul</th>
                <th>Ad</th>
                <th>Email</th>
                <th>Telefon</th>
                <th>Ünvan</th>
                <th>Tarix</th>
              </tr>
            </thead>
            <tbody>
              {products.map((user, key) => (
                <tr key={key}>
                  <td>
                    {/* <Link
                      to={`/dashboard/${user.id}/edit`}
                      style={{ marginRight: "10px" }}
                    >
                      Edit
                    </Link> */}
                    <button onClick={() => deleteUser(user.id)}>Sil</button>
                  </td>
                  <td>{user.id}</td>
                  <td>{user.product}</td>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td>{user.phone}</td>
                  <td>{user.address}</td>
                  <td>{user.created_at}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
    </>
  );
}
