import React, { useEffect, useState } from 'react';
import { about1, about2, about3, about4, about5, about6, about7, about8, about9, service1, service2, service3 } from '../../assets/images'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation } from 'swiper/modules';
import { useTranslation } from 'react-i18next'; 
import ProductCard from './ProductCard.js'
import axios from 'axios';


const Products = () => {


    const { t} = useTranslation();
      const [list, setItems] = useState([]);
      const [isLoading, setIsLoading] = useState(true);

     useEffect(() => {
        getProducts();
      }, []);
    
      const getProducts = async () => {
        try {
          const response = await axios.get(
            `https://www.adb.az/arash/dashboard.php`,
            { withCredentials: false }
          );
      
          // Tüm veriyi al ve sadece ilk 8 tanesini seç
          const filteredProducts = response.data.data.filter(product => product.credit != 0);
      
          setItems(filteredProducts); 
          setIsLoading(false);
        } catch (error) {
          console.error("Error fetching products:", error);
        }
      }; 

  return ( 

        <div className='w-full justify-center items-center relative mt-[88px]'>
        <div className='md:max-w-container max-w-containerSm flex flex-col mx-auto justify-between relative'>
  
          {/* Başlık */}
          <div className='flex justify-between items-center relative'>
          <div className='w-[1040px]'>
          <p className='text-[24px] font-bold text-firstColor font-firstFont'>Kredit üçün seçilən məhsullar</p>
          </div>
          <div className="absolute bottom-0 right-0 gap-2 hidden md:flex">
            <button className="swiper-button-prev-custom border-2 border-[#D9D9DE] hover:border-[#2264DC] rounded-[100px] p-[12px]">
            <i class="fa-solid fa-arrow-left text-[#2264DC]"></i>
            </button>
            <button className="swiper-button-next-custom border-2 border-[#D9D9DE] hover:border-[#2264DC] rounded-[100px] p-[12px]">
            <i class="fa-solid fa-arrow-right text-[#2264DC]"></i>
            </button>
          </div>
          </div>
  
          <div className='mt-8 md:mt-10'>
            <Swiper
              spaceBetween={30}
              loop={true}
              pagination={{ clickable: true }}
              navigation={{
                nextEl: '.swiper-button-next-custom',
                prevEl: '.swiper-button-prev-custom',
              }}
              modules={[Pagination, Navigation]}
              breakpoints={{
                480: { slidesPerView: 1 },
                640: { slidesPerView: 2 },
                768: { slidesPerView: 3 },
                1024: { slidesPerView: 4 },
              }}
            >
                {list.map((product, index) => {
      let images = [];
      try {
        images = product.images ? JSON.parse(product.images) : [];
      } catch (error) {
        console.error("JSON parse error:", error);
      }

      return (
        <SwiperSlide key={index}>
          <ProductCard
            id={product.id}
            name={product.name}
            description={product.description}
            preferences={product.preferences}
            garanty={product.garanty}
            loan={product.loan}
            credit={product.credit}
            new={product.new}
            discount={product.discount}
            technical={product.technical}
            images={images}
          />
        </SwiperSlide>
      );
    })}
            </Swiper>
          </div>
        </div>  
      
       <div className=" flex gap-2 md:hidden w-[100px] mx-auto mt-[32px]">
            <button className="swiper-button-prev-custom border-2 border-[#D9D9DE] hover:border-[#2264DC] rounded-[100px] p-[12px]">
            <i class="fa-solid fa-arrow-left text-[#2264DC]"></i>
            </button>
            <button className="swiper-button-next-custom border-2 border-[#D9D9DE] hover:border-[#2264DC] rounded-[100px] p-[12px]">
            <i class="fa-solid fa-arrow-right text-[#2264DC]"></i>
            </button>
          </div>
      </div>
  )
}
 
export default Products 