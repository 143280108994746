import Products from './Products.js'
import React, { useState } from 'react';
import axios from 'axios';

const Kredit = () => {


  
     const [formData, setFormData] = useState({
            name: '',
            surname: '',
            phone: '',
        });
        const [responseMessage, setResponseMessage] = useState({ text: '', isError: false });
    
        const handleChange = (e) => {
            const { name, value } = e.target;
            setFormData(prev => ({
                ...prev,
                [name]: value
            }));
        };
    
        const handleSubmit = async (event) => {
          event.preventDefault();
        
          // Form validation
          if (!formData.name || !formData.surname || !formData.phone) {
            setResponseMessage({ text: 'Zəhmət olmasa bütün inputları doldurun.', isError: true });
            return;
          }
        
          try {
            // Backend'inizin beklediği form-data formatında gönderim
            const formDataToSend = new FormData();
            formDataToSend.append('name', formData.name);
            formDataToSend.append('surname', formData.surname);
            formDataToSend.append('phone', formData.phone);
        
            const response = await axios.post(
              'https://adb.az/arash/credit.php',
              formDataToSend,
              {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              }
            );
        
            // Backend yanıtını işleme
            const responseData = response.data;
            if (responseData.status === 1) {
              setResponseMessage({ text: responseData.message || 'Veri başarıyla gönderildi!', isError: false });
              // Formu temizle
              setFormData({
                name: '',
                surname: '',
                phone: '',
              });
            } else {
              setResponseMessage({ 
                text: responseData.message || 'Form göndərilmədi', 
                isError: true 
              });
            }
          } catch (error) {
            console.error('API Hatası:', error);
            let errorMessage = 'Sunucuya bağlanırken bir hata oluştu';
            
            // Sunucudan gelen hatayı işleme
            if (error.response) {
              errorMessage = error.response.data?.message || 
                             (error.response.data ? JSON.stringify(error.response.data) : error.message);
            }
            
            setResponseMessage({ 
              text: errorMessage, 
              isError: true 
            });
          }
        };


  return (
    <div className='bg-[#FCFCFC]'>
    <div className='max-w-containerSm md:max-w-container mx-auto pb-[88px] md:pb-[112px]'>
    <div className='pt-[56px] text-[14px] text-firstColor flex items-center font-secondFont'>
      <p>Ana səhifə</p>
      <i className={`fas fa-chevron-right mx-2`} /> 
      <p>Kredit</p>
    </div>
    <div className='mt-[48px] md:mt-[56px] md:flex justify-between'>
     <div className='md:w-[385px] text-firstColor font-secondFont'>
   <p className='text-[24px] font-bold'>Kredit şərtləri</p>
   <p className='mt-[22px]'> ✅ İlkin ödəniş: 20%</p>
   <p> ✅ Müddət: 6-12 ay</p>
   <p> ✅ Faiz dərəcəsi: 0%-dən başlayaraq</p>
   <p> ✅ Təsdiq: 1 gün ərzində</p>
   <p> ✅ Sənədləşmə: Minimum sənəd tələb olunur</p>
   <p className='mt-[22px]'> 📞 Ətraflı məlumat üçün bizə müraciət edin! 🚀</p>
     </div>
     <div className='md:w-[628px] mt-[48px] md:mt-0'>
     <form className="font-secondFont" onSubmit={handleSubmit}>
                            <p className="text-thirdColor text-[14px]">Ad</p>
                            <input
                                name="name"
                                className="rounded-lg h-[56px] w-full p-4 border-[1px] border-[#CFCFCF] mt-2 outline-none focus:ring-0 focus:outline-none"
                                placeholder="Type here"
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />

                            <p className="text-thirdColor text-[14px] mt-5">Soyad</p>
                            <input
                                name="surname"
                                className="rounded-lg h-[56px] w-full p-4 border-[1px] border-[#CFCFCF] mt-2 outline-none focus:ring-0 focus:outline-none"
                                placeholder="Type here"
                                value={formData.surname}
                                onChange={handleChange}
                                required
                            />

                            <p className="text-thirdColor text-[14px] mt-5">Nömrə</p>
                            <input
                                name="phone"
                                className="rounded-lg h-[56px] w-full p-4 border-[1px] border-[#CFCFCF] mt-2 outline-none focus:ring-0 focus:outline-none"
                                placeholder="Type here"
                                value={formData.phone}
                                onChange={handleChange}
                                required
                            />

                            <button
                                type="submit"
                                className="w-full h-[44px] rounded-2xl font-secondFont bg-[#2264DC] text-white mt-[48px] hover:bg-[#1a52b5] transition-colors"
                            >
                                Göndər
                            </button>

                            {responseMessage.text && (
                                <p className={`mt-4 text-center ${responseMessage.isError ? 'text-red-500' : 'text-green-500'}`}>
                                    {responseMessage.text}
                                </p>
                            )}
                        </form>
     </div>
    </div>
    <div>
      <Products/>
    </div>
    </div>
    </div>
  )
}

export default Kredit 