import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';
import { rightUp } from '../../assets/images';

function PaginatedItems({ itemsPerPage, items }) {
  const { t } = useTranslation();
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0); 

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, items]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      <div className='grid grid-cols-1 md:grid-cols-3 gap-5'>
        {currentItems.map((item, index) => {
          // images string olarak geliyor, parse et ve kontrol et
          let images = []; 
          try {
            images = item.images ? JSON.parse(item.images) : [];
          } catch (error) {
            console.error("JSON parse error:", error);
          }

          return (
            <a href={`/product-detail/${item.id}`} key={index}>
              <div className='bg-white rounded-[24px] w-full md:w-[305px] min-h-[383px] mx-auto p-5 border border-[#CFCFCF] group'>
        <div className='text-[#0B0B0B]'>
          {/* Image Container with Hover Effects */}
          <div className='bg-white h-[224px] flex justify-center items-center relative overflow-hidden'>
            <img className='w-full h-full transition-all duration-300 group-hover:scale-110' 
                 src={`https://www.adb.az/arash/${images[0]}`} 
                 alt={item.name} 
            />
            {/* Blue Overlay on Hover */}
            <div className="absolute inset-0 bg-[#DBE5FF] opacity-0 group-hover:opacity-50 transition-opacity duration-300"></div>
            
            {/* Centered Icon on Hover */}
            <div className="absolute inset-0 flex justify-center items-center opacity-0 group-hover:opacity-100 transition-all duration-300">
              <img className="w-12 h-12 text-white"  src={rightUp}></img>
            </div>
          </div>

          {/* Product Details */}
          <p className='text-[20px] font-bold mt-5 text-firstColor font-firstFont group-hover:text-[#2264DC]'>{item.name}</p>
          <p className="text-[16px] mt-2 text-firstColor font-secondFont" 
            dangerouslySetInnerHTML={{ __html: item.description?.slice(0, 50) + '...' }} 
          />
        </div>
      </div>
            </a>
          );
        })}
      </div>

      <ReactPaginate
        nextLabel="sonrakı"
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        pageCount={pageCount}
        previousLabel="əvvəlki"
        renderOnZeroPageCount={null}
        containerClassName="flex justify-center items-center space-x-1 my-4"
        pageClassName="inline-block px-2 py-1 text-sm md:text-base rounded bg-gray-200 hover:bg-gray-300"
        previousClassName="inline-block px-2 py-1 text-sm md:text-base rounded bg-gray-200 hover:bg-gray-300"
        nextClassName="inline-block px-2 py-1 text-sm md:text-base rounded bg-gray-200 hover:bg-gray-300"
        activeClassName="bg-blue-600 text-white"
        disabledClassName="text-gray-400 cursor-not-allowed"
      />
    </>
  );
}

export default PaginatedItems;
