import React, { useEffect, useRef, useState } from "react";
import Others from "./Others";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import StarRatings from 'react-star-ratings';  //
import { person } from "../../assets/images";

export default function ProductDetail() {
  const { t } = useTranslation();
  const [list, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeModalIndex, setActiveModalIndex] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0); // Aktif slide index
  const swiperRef = useRef(null); // Swiper referansı

  // Parse the image URLs if they are passed as a JSON string
  const parseImages = (images) => {
    try {
      return images ? JSON.parse(images) : [];
    } catch (error) {
      console.error("Error parsing images:", error);
      return [];
    }
  };

  // Get images list
  const images = parseImages(list.images);

  // Modal'ı açma fonksiyonu
  const openModal = (index) => {
    setActiveModalIndex(index);
    setIsModalOpen(true);
  };

  const changeImage = (direction) => {
    const newIndex = (activeModalIndex + direction + images.length) % images.length;
    setActiveModalIndex(newIndex);
  };

  // Modal'ı kapama fonksiyonu
  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    try {
      const response = await axios.get(
        `https://www.adb.az/arash/dashboard.php`,
        { withCredentials: false }
      );

      const filteredProducts = response.data.data.filter(product => product.id == id);
      setItems(filteredProducts[0]);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

    // Parse comments if available
    const parseComments = (comments) => {
      try {
        return comments ? JSON.parse(comments) : [];
      } catch (error) {
        console.error("Error parsing comments:", error);
        return [];
      }
    };
  
    const comments = parseComments(list.comments);

  return (  
    <>
        <div className='bg-[#FCFCFC]'>
      <div className="md:max-w-container max-w-containerSm mx-auto pb-[88px] md:pb-[112px]">
      <div className='pt-[56px] text-[14px] text-firstColor flex items-center font-secondFont'>
          <p>Ana səhifə</p>
          <i className={`fas fa-chevron-right mx-2`} /> 
          <p>Məhsullar</p>
          <i className={`fas fa-chevron-right mx-2`} /> 
          <p>{list.name}</p>
        </div>

        <div className="md:flex justify-between mt-[48px] md:mt-[56px]">
          <div className="md:w-[475px]">
            <div className="bg-[white] mb-3 flex justify-center items-center md:w-[475px] h-[411px] rounded-2xl border border-[#e2e2e2]">
              <Swiper
                ref={swiperRef}
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                  delay: 5000,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                navigation={true}
                onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
                modules={[Autoplay, Pagination, Navigation]}
                className="main-slider"
              >
                {images.map((image, index) => (
                  <SwiperSlide key={index}>
                    <div className="relative bg-white w-full h-[354px] rounded-xl flex items-center justify-center cursor-pointer">
                      <img
                        className="max-w-full max-h-full object-contain"
                        src={`https://www.adb.az/arash/${image}`}
                        alt={`Slide ${index + 1}`}
                        onClick={() => openModal(index)} // Open modal with the clicked index
                      />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>

            {/* Thumbnail images below the main image */}
            <div className="flex justify-center mt-5 space-x-3">
              {images.map((slide, index) => (
                <div
                  key={index}
                  className={`cursor-pointer border-2 ${
                    index === activeIndex ? "border-[#264D92]" : "border-transparent"
                  } rounded-lg bg-white w-[80px] h-[60px] flex items-center justify-center`}
                  onClick={() => swiperRef.current.swiper.slideTo(index)} // Click to change main image
                >
                  <img
                    className="max-w-full max-h-full object-contain"
                    src={`https://www.adb.az/arash/${slide}`}
                    alt={`Thumbnail ${index + 1}`}
                  />
                </div>
              ))}
            </div>

            {/* Modal for viewing enlarged image */}
            {isModalOpen && (
              <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-75 flex items-center justify-center z-50">
                <div className="relative">
                  <button
                    onClick={closeModal}
                    className="absolute top-2 right-2 text-[#264D92] text-4xl font-bold z-20"
                  >
                    ×
                  </button>

                  <div className="bg-white md:w-[500px] md:h-[500px] rounded-lg flex items-center justify-center relative">
                    <button
                      className="absolute left-0 text-white text-4xl font-bold bg-black bg-opacity-50 px-3 py-2 z-10"
                      onClick={() => changeImage(-1)}
                    >
                      &#60;
                    </button>

                    {/* Resmi gösterirken, `images[activeModalIndex]` nesnesinin geçerli olup olmadığını kontrol edelim */}
                    {images[activeModalIndex] && (
                      <img
                        className="max-w-full max-h-full object-contain"
                        src={`https://www.adb.az/arash/${images[activeModalIndex]}`}
                        alt="Selected"
                      />
                    )}

                    <button
                      className="absolute right-0 text-white text-4xl font-bold bg-black bg-opacity-50 px-3 py-2 z-10"
                      onClick={() => changeImage(1)}
                    >
                      &#62;
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="md:w-[774px] font-secondFont text-secondColor mt-[65px] md:mt-0">
            <h4 className="text-[24px] font-bold mb-2 text-[#2264DC]">{list.name}</h4>
            <div className="w-full h-[0px] border border-[#cfcfcf] mt-4"></div>
            <p className="font-semibold text-firstColor mt-7 font-firstFont">Ətraflı məlumat:</p>
            <p className="mb-9 text-sm md:text-[16px] mt-4" dangerouslySetInnerHTML={{ __html: list.description }} />
            <p className="font-semibold text-firstColor font-firstFont">Üstünlükləri:</p>
            <p className="mb-9 text-sm md:text-[16px] mt-4" dangerouslySetInnerHTML={{ __html: list.preferences }} />
            <p className="font-semibold text-firstColor font-firstFont">Texniki göstəricilər:</p>
            <p className="mb-9 text-sm md:text-[16px] mt-4" dangerouslySetInnerHTML={{ __html: list.technical }} />
            <div className="w-full h-[0px] border border-[#cfcfcf] mt-4"></div>
            <p className="font-semibold text-firstColor mt-7 font-firstFont">Zəmanət:</p>
            <p className="mb-9 text-sm md:text-[16px] mt-4" dangerouslySetInnerHTML={{ __html: list.garanty }} />

            <div className="md:flex justify-end mt-6 gap-4">
              <a href="/contact">
                <button className="mt-4 px-4 py-2 bg-[#2264DC] rounded-[12px] w-full text-white font-secondFont">
                  {t("Konsultasiya al")}
                </button>
              </a>
              {list.credit == 1 && (
                <a href="/credit">
                  <button className="mt-4 px-4 py-2 bg-[#2264DC] rounded-[12px] w-full text-white font-secondFont">
                    {t("Kreditlə al")}
                  </button>
                </a>
              )}
              {list.loan == 1 && (
                <a href="/icare">
                  <button className="mt-4 px-4 py-2 bg-[#2264DC] rounded-[12px] w-full text-white font-secondFont">
                    {t("İcarə ilə al")}
                  </button>
                </a>
              )}
            </div>
          </div>
        </div>

        {
          list.link && <div className="mt-[30px] md:mt-[48px]">
          <h4 className="text-[24px] font-bold mb-2 text-[#0B0B0B] font-firstFont">Məhsulun Təqdimatı</h4>
          <div className="w-full h-[0px] border border-[#cfcfcf] mt-8"></div>
          <iframe
            width="100%"
            height="369"
            src={list.link}
            title="YouTube video player"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
            allowFullScreen
          ></iframe>
        </div>
        }

        {comments && 
        <div className="mt-12">
          <h4 className="text-[24px] font-bold mb-2 text-[#0B0B0B] font-firstFont">İstifadəçi rəyləri</h4>
          <div className="w-full h-[0px] border border-[#cfcfcf] mt-4"></div>
          <div className="mt-4">
          {comments.map((comment, index) => (
  <div key={index} className="mb-6 rounded-xl p-4 flex border border-[#CFCFCF] font-secondFont">
    <img className="w-[38px] h-[38px] mr-6" src={person} alt="User Avatar" />
    <div className="flex flex-col">
      <div className="font-semibold font-firstFont">{comment.name}</div>
      <div className="text-sm text-gray-600">{comment.date}</div>
    </div>
    <div className="mx-6 bg-[#9CA3AF] h-[45px] w-[1px]"></div>
    <div className="flex flex-col ml-6">
      <StarRatings
        rating={comment.rating || 0} // Assume `rating` is a property of the comment
        starRatedColor="#FDB85C"
        numberOfStars={5}
        starSpacing="1px"
        starDimension="13px"
      />
      <div className="mt-[2px]">{comment.comment}</div>
    </div>
  </div>
))}

          </div>
        </div>}
        <Others />
      </div>
      </div>

    </>
  );
}

