import React from 'react';
import { about, rightUp } from '../../assets/images';


const ProductCard = ({ index, name, id, description, images }) => {
  return (
    <a href={`/product-detail/${id}`} key={index}>
      <div className='bg-white rounded-[24px] w-full md:w-[305px] min-h-[383px] mx-auto p-5 border border-[#CFCFCF] group'>
        <div className='text-[#0B0B0B]'>
          {/* Image Container with Hover Effects */}
          <div className='bg-white h-[224px] flex justify-center items-center relative overflow-hidden'>
            <img className='w-full h-full transition-all duration-300 group-hover:scale-110' 
                 src={`https://www.adb.az/arash/${images[0]}`} 
                 alt={name} 
            /> 
            {/* Blue Overlay on Hover */}
            <div className="absolute inset-0 bg-[#DBE5FF] opacity-0 group-hover:opacity-50 transition-opacity duration-300"></div>
            
            {/* Centered Icon on Hover */}
            <div className="absolute inset-0 flex justify-center items-center opacity-0 group-hover:opacity-100 transition-all duration-300">
              <img className="w-12 h-12 text-white"  src={rightUp}></img>
            </div>
          </div>

          {/* Product Details */}
          <p className='text-[20px] font-bold mt-5 text-firstColor  group-hover:text-[#2264DC] font-firstFont'>{name}</p>
          <p className="text-[16px] mt-2 text-firstColor font-secondFont" 
            dangerouslySetInnerHTML={{ __html: description?.slice(0, 50) + '...' }} 
          />
        </div>
      </div>
    </a>
  );
};

export default ProductCard;
