import { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import DashboardHeader from "../../Components/DashboardHeader/DashboardHeader";

export default function EditPhotos() {

  const [image, setImage] = useState(null); // Resim için ayrı bir state
  const { id } = useParams();

  useEffect(() => {
    getProducts();
  }, []);


  function getProducts() {
    axios
      .get(`https://www.safqida.az/safqida/categories.php/${id}`)
      .then((response) => {
        const data = response.data;
        if (data.image) {
          setImage(data.image); // Base64 kodlanmış görsel
        }
      });
  }
  

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result;
          const base64WithoutHeader = base64String.replace(
            /^data:image\/[a-z]+;base64,/,
            ""
          );
      setImage(base64WithoutHeader); // Base64 formatında set et
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };
  

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(image)
  
    const payload = {
      id,
      image, // Base64 formatında
    };
  
    await axios
      .put(`https://www.tanura.az/tanura/photos.php/${id}/edit`, payload)
      .then(() => {
        window.history.back();
      });
  };
  

  return (
    <>
         <DashboardHeader />
     <div>
      <div className="max-w-container mx-auto">
        <form onSubmit={handleSubmit}>
          <table cellSpacing="10">
            <tbody>
              <tr>
                <th>
                  <label>Şəkil: </label>
                </th>
                <td>
                  <input type="file" accept="image/*" onChange={handleImageChange} />
                </td>
              </tr>
              <tr>
                <td colSpan="2" align="right">
                  <button type="submit">Yadda saxla</button>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </div>
     </div>
    </>
  );
}
