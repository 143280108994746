import axios from "axios";
import React, { useEffect, useState } from "react";
import "./dashboard.css";
import DashboardHeader from "../../Components/DashboardHeader/DashboardHeader";
import { NavLink } from "react-router-dom";

export default function DashboardPhotos() {

  const [categories, setCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

useEffect(() => {
  getProducts();
}, []);
 
async function getProducts() {
  try { 
    // API'den verileri çekiyoruz
    const response = await axios.get('https://www.tanura.az/tanura/photos.php');
    setCategories(response.data);  // Gelen veriyi state'e kaydediyoruz
    console.log(response.data)
    setIsLoading(false)
  } catch (error) {
    console.error('Error fetching categories:', error);  // Hata durumunda mesaj yazdırıyoruz
  }
}

if (isLoading) {
  return <p className="text-center text-[black]">Loading...</p>;
}
  
  
  return (
    <>
    <DashboardHeader/>
    <div className="mt-8 grid grid-cols-2 md:grid-cols-3 gap-4">
    {categories.map((category, index) => (
      <NavLink
      key={category.name}
      to={`/dashboard/images/${category.id}/edit`}
    >
      <div key={index} className="text-center">
        <img 
          src={`data:image/jpeg;base64,${category.image}`}
          className="w-48 h-48 mx-auto object-cover"
        />
      
      </div>
      </NavLink>
    ))}
  </div>
    </> 
  );
}
