import React, { useState, useEffect } from 'react'
import { about, arashBg4, arashBg5, logo } from '../../assets/images'
import { useTranslation } from 'react-i18next';
import DiscountProducts from '../../Components/home/DiscountProducts/DiscountProducts'
import axios from 'axios';

const Kampaniya = () => {

    const [list, setItems] = useState([]);
      const [isLoading, setIsLoading] = useState(true);

    const { t } = useTranslation();

    const [visibleCount, setVisibleCount] = useState(4); // Başlangıçta 3 kampaniya göster

    const [showModal, setShowModal] = useState(false);

    const closeModal = () => {
      setShowModal(false);
    };

useEffect(() => {
  const timer = setTimeout(() => {
    setShowModal(true);
  }, 5000); // 5 saniye sonra modal göster

  return () => clearTimeout(timer); // Sayfa değişirse veya bileşen unmount olursa timer temizlensin
}, []);

    useEffect(() => {
      getProducts();
    }, []);
  
    const getProducts = async () => {
      try {
        const response = await axios.get(
          `https://www.adb.az/arash/kampaniya.php`,
          { withCredentials: false }
        );
     console.log(response.data.data)
        setItems(response.data.data); 
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };
 
    const loadMore = () => {
    setVisibleCount((prevCount) => prevCount + 3); // 3'er 3'er artır
  };
  
    // const kampaniyalar = [
    //   {id: 1,  image: logo, date: "11 February 2023", title: "Tech Giants Forge Partnerships to Accelerate Innovation in Artificial Intelligence", content: "Leading technology companies have announced strategic partnerships aimed at advancing innovation in artificial intelligence (AI) and machine learning. By pooling their resources and expertise, these industry giants aim to tackle complex AI challenges, drive breakthroughs in AI research, and bring transformative AI-powered products and services to market." },
    //   {id:2,  image: about, date: "11 February 2023", title: "Tech Giants Forge Partnerships to Accelerate Innovation in Artificial Intelligence", content: "Leading technology companies have announced strategic partnerships aimed at advancing innovation in artificial intelligence (AI) and machine learning. By pooling their resources and expertise, these industry giants aim to tackle complex AI challenges, drive breakthroughs in AI research, and bring transformative AI-powered products and services to market." },
    //   {id: 3, image: about, date: "11 February 2023", title: "Tech Giants Forge Partnerships to Accelerate Innovation in Artificial Intelligence", content: "Leading technology companies have announced strategic partnerships aimed at advancing innovation in artificial intelligence (AI) and machine learning. By pooling their resources and expertise, these industry giants aim to tackle complex AI challenges, drive breakthroughs in AI research, and bring transformative AI-powered products and services to market." },
    //   {id:4, image: about, date: "11 February 2023", title: "Tech Giants Forge Partnerships to Accelerate Innovation in Artificial Intelligence", content: "Leading technology companies have announced strategic partnerships aimed at advancing innovation in artificial intelligence (AI) and machine learning. By pooling their resources and expertise, these industry giants aim to tackle complex AI challenges, drive breakthroughs in AI research, and bring transformative AI-powered products and services to market." },
    //   {id:5, image: about, date: "11 February 2023", title: "Tech Giants Forge Partnerships to Accelerate Innovation in Artificial Intelligence", content: "Leading technology companies have announced strategic partnerships aimed at advancing innovation in artificial intelligence (AI) and machine learning. By pooling their resources and expertise, these industry giants aim to tackle complex AI challenges, drive breakthroughs in AI research, and bring transformative AI-powered products and services to market." },
     
    // ];

  return (
    <div className='bg-[#FCFCFC]'>
     <div className='max-w-containerSm md:max-w-container mx-auto pb-[88px] md:pb-[112px]'>
     <div className='pt-[56px] text-[14px] text-firstColor flex items-center font-secondFont'>
      <p>Ana səhifə</p>
      <i className={`fas fa-chevron-right mx-2`} /> 
      <p>Kampaniya</p>
    </div>
    { list.length > 0 && ( <div className='mt-[56px] hidden md:block'>
    <div className="relative w-full">
  <img src={`https://adb.az/arash/${list[0].image}`} className="w-full" alt="Logo" />
  <div className="absolute bottom-0 left-10 bg-opacity-10 pb-3 text-[#F9FAFC] text-2xl font-bold w-[900px]">
    <p className='font-secondFont text-[14px]'>{list[0].date}</p>
    <p className='font-firstFont text-[32px] font-bold mt-[12px]'>{list[0].title}</p>
    <p className='font-secondFont mt-[18px]'>{list[0].content.slice(0, 250)}...</p>
  </div>
</div>
<a href={`/kampaniya/${list[0].id}`}>
<div className='flex justify-between items-center mt-[24px] px-12 text-[#162D59]  w-[211px] h-[44px] mx-auto'>
  <p className='mr-10'>Ətraflı</p>
  <i className={`fas fa-chevron-right text-[#162D59]`} />
</div>
</a>

    </div>)}
    <div className='md:grid grid-cols-1 md:grid-cols-3 mt-[56px] md:mt-10 gap-5 hidden '>
    {list?.slice(1, visibleCount).map((item, index) => (
     <div key={index} className='p-4'>
       <img className='h-[248px] w-full' src={`https://adb.az/arash/${item.image}`} alt={item.title} />
       <p className='text-[#A3A3A3] text-[14px] font-secondFont mt-2'>{item.date}</p>
       <p className='text-firstColor text-[18px] font-bold font-firstFont mt-2'>{item.title}</p>
       <p className='text-[#696868] font-secondFont mt-2'>{item.content.slice(0, 50)}...</p>
       <a href={`kampaniya/${item.id}`}>
       <div className='flex justify-between items-center mt-2 px-12 text-[#162D59] w-[211px] h-[44px] mx-auto'>
         <p className='mr-10'>Ətraflı</p>
         <i className={`fas fa-chevron-right text-[#162D59]`} />
       </div>
       </a>
     </div>
    ))}
    </div>
    <div className='grid grid-cols-1 md:grid-cols-3 mt-[56px] md:mt-10 gap-5 md:hidden'>
    {list?.slice(0, visibleCount).map((item, index) => (
     <div key={index} className='p-4'>
       <img className='h-[248px] w-full' src={`https://adb.az/arash/${item.image}`} alt={item.title} />
       <p className='text-[#A3A3A3] text-[14px] font-secondFont mt-2'>{item.date}</p>
       <p className='text-firstColor text-[18px] font-bold font-firstFont mt-2'>{item.title}</p>
       <p className='text-[#696868] font-secondFont mt-2'>{item.content.slice(0, 50)}...</p>
       <a href={`kampaniya/${item.id}`}>
       <div className='flex justify-between items-center mt-2 px-12 text-[#162D59]  w-[211px] h-[44px] mx-auto'>
         <p className='mr-10'>Ətraflı</p>
         <i className={`fas fa-chevron-right text-[#162D59]`} />
       </div>
       </a>
     </div>
    ))}
    </div>
    {visibleCount < list.length && (
          <div className='flex justify-center'>
            <button
              onClick={loadMore}
              className="md:w-[203px] w-full h-[44px] rounded-2xl font-secondFont bg-[#2264DC] text-white mt-[40px] md:mt-[64px]"
            >
              Daha çox 
            </button> 
          </div>
        )}
        <div  style={{
                backgroundImage: `url(${arashBg4})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
              }} className='border hidden border-[#2264DC] mt-[88px] md:mt-[128px] rounded-[20px] h-[363px] md:h-[300px] md:flex justify-between p-[25px] md:p-[70px]'>
      <div className='md:w-[520px] text-firstColor'>
        <p className='text-[24px] md:text-[32px] font-bold font-firstFont'>Ən son kampaniyalardan xəbərdar ol!</p>
        <p className='mt-4 font-secondFont'>Kampaniyalardan xəbərdar olmaq üçün emailinizi qeyd edin və fürsətləri qaçırmayın!</p>
        </div>
      <div className='md:w-[407px] flex flex-col justify-center mt-6 md:mt-0'>
     <input className='rounded-lg border border-[#CFCFCF] p-4 h-[56px] w-full outline-none focus:ring-0 focus:outline-none' placeholder='E-mail adresi'></input>
     <button className="w-full h-[44px] rounded-2xl bg-[#2264DC] text-white mt-[20px] md:mt-[30px] font-secondFont">Göndər</button>
      </div>
        </div> 
        <div  style={{
                backgroundImage: `url(${arashBg5})`,
                backgroundSize: 'cover', 
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
              }} className='border border-[#2264DC] block mt-[88px] md:mt-[128px] rounded-[20px] h-[363px] md:h-[300px] md:hidden justify-between p-[25px] md:p-[70px]'>
      <div className='md:w-[520px] text-firstColor'>
        <p className='text-[24px] md:text-[32px] font-bold font-firstFont'>Ən son kampaniyalardan xəbərdar ol!</p>
        <p className='mt-4 font-secondFont'>Kampaniyalardan xəbərdar olmaq üçün emailinizi qeyd edin və fürsətləri qaçırmayın!</p>
        </div>
      <div className='md:w-[407px] flex flex-col justify-center mt-6 md:mt-0'>
     <input className='rounded-lg border border-[#CFCFCF] p-4 h-[56px] w-full outline-none focus:ring-0 focus:outline-none' placeholder='E-mail adresi'></input>
     <button className="w-full h-[44px] rounded-2xl bg-[#2264DC] text-white mt-[20px] md:mt-[30px] font-secondFont">Göndər</button>
      </div>
        </div>
        <div>
        <DiscountProducts/>
        </div>
    </div>
    {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="relative bg-[white] rounded-[20px] py-[32px] md:py-[53px] px-[19px] md:px-[60px] w-[343px] md:w-[646px] shadow-lg">
            {/* Close Button */}
            <button
              onClick={closeModal}
              className="absolute top-2 right-4 text-gray-500 hover:text-black text-[30px]"
            >
              &times;
            </button>

            <h2 className="text-[18px] font-firstFont font-bold text-firstColor text-center"> Kampaniyadan Yararlanmaq Şansı!</h2>
            <p className="mt-6 font-secondFont text-firstColor text-center text-[14px] md:text-[16px]">Sənin üçün xüsusi təklifimiz var! Bu fürsəti qaçırmamaq üçün indi müraciət et və kampaniyanın üstünlüklərindən yararlan.</p>
            <a href='/contact'>
            <div className='flex justify-center'>
            <button
              className="md:w-[203px] w-full h-[44px] rounded-2xl font-secondFont bg-[#2264DC] text-white mt-[40px]"
            >
              İndi müraciət et 
            </button> 
            </div>
            </a>
          </div>
        </div>
      )}

</div>

  )
} 

export default Kampaniya