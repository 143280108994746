import {
    createBrowserRouter,
    RouterProvider,
    Outlet,
    createRoutesFromElements,
    Route,
    ScrollRestoration,
  } from "react-router-dom";
  import Header from "./Components/Header/Header";
  import Footer from "./Components/Footer/Footer";
  import Home from "../src/Pages/Home/Home";
  
  import { ToastContainer } from "react-toastify";
  import About from "./Pages/About/About";
  import Contact from "./Pages/Contact/Contact";
  import Products from "./Pages/Products/Products";
  import Blogs from "./Pages/Blogs/Blogs";
  import ProductDetail from "./Pages/ProductDetail/ProductDetail";
  import Protected from "./Pages/Protected/Protected";
  import Dashboard from "./Pages/Dashboard/Dashboard";
  import AdminPanel from "./Pages/AdminPanel/AdminPanel";
import CreateUser from "./Pages/CreateUser/CreateUser";
import DashboardContact from "./Pages/DashboardContact/DashboardContact";
import DashboardOffer from "./Pages/DashboardOffer/DashboardOffer"
import DashboardOrders from "./Pages/DashboardOrders/DashboardOrders";
import EditUser from "./Pages/EditUser/EditUser";
import BlogDetail from "./Pages/BlogDetail/BlogDetail";
import Kampaniya from "./Pages/Kampaniya/Kampaniya";
import KampaniyaDetail from "./Pages/KampaniyaDetail/KampaniyaDetail";
import DashboardPhotos from "./Pages/DashboardPhotos/DashboardPhotos";
import EditPhotos from "./Pages/EditPhotos/EditPhotos";
import Service from "./Pages/Service/Service";
import Kredit from "./Pages/Kredit/Kredit";
import Icare from "./Pages/Icare/Icare";
import AboveHeader from "./Components/AboveHeader/AboveHeader";
import Brands from "./Pages/Brands/Brands";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import WithoutSearchLast from "./Components/Header/WithoutSearchLast";
  
  
  const Layout = () => {
    return (
      <div>
         <ToastContainer
          position="top-right"
          autoClose={1000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        /> 
        <AboveHeader/>
        <Header />
        <ScrollRestoration />
        <Outlet />
        <Footer />
      </div>
    );
  };
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        <Route path="/" element={<Layout />}>
          {/* ==================== Header Navlink Start here =================== */}
          <Route path="/" element={ <Home />}></Route>
          <Route path="/about" element={ <About/>}></Route> 
          <Route path="/products" element={ <Products/>}></Route>
          <Route path="/product-detail/:id" element={ <ProductDetail/>}></Route>
          <Route path="/service" element={ <Service/>}></Route>
          <Route path="/kredit" element={ <Kredit/>}></Route>
          <Route path="/icare" element={ <Icare/>}></Route>
          <Route path="/brands" element={ <Brands/>}></Route>
          <Route path="/blogs" element={ <Blogs/>}></Route> 
          <Route path="/blogs/:id" element={ <BlogDetail/>}></Route>
          <Route path="/kampaniya" element={ <Kampaniya/>}></Route>
          <Route path="/kampaniya/:id" element={ <KampaniyaDetail/>}></Route>
          <Route path="/contact" element={ <Contact/>}></Route>

          <Route path="/admin" element={ <AdminPanel />}></Route>
          <Route path="/dashboard" element={<Protected Component={Dashboard} />} />
          <Route path="/dashboard/category1/:category1" element={<Protected Component={DashboardOffer} />}></Route>
        <Route path="/dashboard/create" element={<Protected Component={CreateUser} />}></Route>
            <Route path="/dashboard/orders" element={<Protected Component={DashboardOrders} />} />
            <Route path="/dashboard/contact" element={<Protected Component={DashboardContact} />} />
            <Route path="/dashboard/photos" element={<Protected Component={DashboardPhotos} />} />
            <Route path="/dashboard/images/:id/edit" element={<Protected Component={EditPhotos} />} />
            <Route path="/dashboard/:id/edit" element={<Protected Component={EditUser} />} />
        </Route>
      </Route>
    )
  );
   
  function App() {
  
    return (
      <div>
        <FloatingWhatsApp phoneNumber='+994502269997' accountName="Arash" statusMessage='' chatMessage='Salam, sizə necə kömək edə bilərik?' placeholder='Mesaj yazın...' avatar='./avatar.png' messageDelay='1' />
        <RouterProvider router={router} />
      </div>
    ); 
  }
  
  export default App;
  
  
  