import React, { useEffect, useState } from 'react'
import { about, about1, about2, about3, contact, facebook, instagram, product1, product2, product3, product4 } from '../../../assets/images';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';


 
const Brands = () => {

  
  const navigate = useNavigate()

  const [brands, setBrands] = useState([]);
  const [clientName, setclientName] = useState("");
  const [clientEmail, setclientEmail] = useState("");
  const [clientPhone, setclientPhone] = useState("");
  const [clientMessage, setclientMessage] = useState("");

   useEffect(() => {
      getBrands();
    }, []);
  
    const getBrands = async () => {
      try {
        const response = await axios.get(
          `https://www.adb.az/arash/brands.php`,
          { withCredentials: false }
        );

        const products = response.data.data
        const limitedData = products?.slice(0, 6);
        console.log(limitedData)
        setBrands(limitedData);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };


  const handleSubmitContact = async (e) => {
    e.preventDefault();
  
    let inputs = { name: clientName, email: clientEmail, phone: clientPhone, message: clientMessage };
    
    try {
      console.log(inputs);
      
      // Seçilen ürünleri post isteği ile gönder
      const response = await axios.post("https://www.tanura.az/tanura/contact.php/", inputs);
      
      // Başarılı olduğunda yapılacak işlemler
      toast.success("Form göndərildi");
      
      // Clear form after successful submission
      setclientName('');
      setclientEmail('');
      setclientPhone('');
      setclientMessage('');
      
      navigate('/');
      
    } catch (error) {
      // Hata durumunda yapılacak işlemler
      console.error("Sipariş gönderilirken bir hata oluştu:", error);
      toast.error("Form göndərilərkən xəta baş verdi");
    }
  };

  const { t, i18n } = useTranslation();
  return (
      <div className="md:max-w-container max-w-containerSm mx-auto mt-[88px] md:mt-[128px]">
           <div className='flex justify-center md:justify-between '>
               <p className="text-[24px] md:text-[32px] font-bold text-center font-firstFont text-firstColor ">{t("Brendlərimiz")}</p>
                 <a className='hidden md:block' href='/brands'>
                   <div className='flex justify-between items-center rounded-[16px] bg-[#2264DC] font-secondFont text-white w-[203px] h-[44px] px-[20px]'>
                      <p>Hamısına bax</p>
                      <img className='h-6 w-6' src={about2}></img>
                    </div>
                   </a>
               </div>
   <div className='mt-[64px] grid md:grid-cols-6 grid-cols-3 gap-[75px]'>
    {brands?.map((slide, index) => (
  <img className='md:w-[150px] w-[80px] h-[37px] md:h-[70px]' src={`https://www.adb.az/arash/${slide.image}`}></img>
            ))}
   </div>
   <a className='block md:hidden' href='/brands'>
                  <div className='flex justify-between items-center rounded-[16px] bg-[#2264DC] font-secondFont text-white w-full mt-[48px] h-[44px] px-[20px]'>
                     <p>Hamısına bax</p>
                     <img className='h-6 w-6' src={about2}></img>
                   </div>
                  </a>
  </div>
  )
}

export default Brands