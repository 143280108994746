import React, { useEffect, useState } from 'react';
import ProductCard from './ProductCard';
import { about2, product1, product2, product3, product4 } from '../../../assets/images';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

const DiscountProducts = () => {

  const { t, i18n } = useTranslation();
  const [list, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

      const [activeIndex, setActiveIndex] = useState(0);
      const [showText, setShowText] = useState(false);
      const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    try {
      const response = await axios.get(
        `https://www.adb.az/arash/dashboard.php`,
        { withCredentials: false }
      );
  
      // Tüm veriyi al ve sadece ilk 8 tanesini seç
      const filteredProducts = response.data.data.filter(product => product.discount != 0);
      const limitedData = filteredProducts?.slice(0, 4);
  
      console.log(limitedData);
      setItems(limitedData); 
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };
  

  if (isLoading) {
    return <p className="text-center text-[white]">Loading...</p>;
  }

  return (
      <div className="max-w-containerSm md:max-w-container mx-auto mt-[88px] md:mt-[128px]">
       <div className='flex md:justify-between justify-center '>
           <p className="text-[24px] md:text-[32px] font-bold text-center md:text-left font-firstFont text-firstColor">{t("Endirimli məhsullar")}</p>
             <a className='hidden md:block' href='/products'>
               <div className='flex justify-between items-center rounded-[16px] bg-[#2264DC] font-secondFont text-white w-[203px] h-[44px] px-[20px]'>
                  <p>Hamısına bax</p>
                  <img className='h-6 w-6' src={about2}></img>
                </div>
               </a>
           </div>
        <div className="hidden md:grid grid-cols-1 md:grid-cols-4 gap-6 mt-[48px]">
        {list.map((product) => {
          let images = [];
          try {
            images = product.images ? JSON.parse(product.images) : [];
          } catch (error) {
            console.error("JSON parse error:", error);
          }

         return (
          <ProductCard
          id={product.id}
                         name={product.name}
                         description={product.description}
                         preferences={product.preferences}
                         garanty={product.garanty}
                         loan={product.loan}
                         credit={product.credit}
                         new={product.new}
                         discount={product.discount}
                         technical={product.technical}
                         images={images} 
          />

                  );
                })}

      </div>
      <div className='block md:hidden mt-[48px] '>
  <Swiper
    slidesPerView={1}
    spaceBetween={20}
    loop={true}
    autoplay={{
      delay: 4000,
      disableOnInteraction: false,
    }}
    pagination={{
      clickable: true,
    }}
    onSlideChange={(swiper) => {
      setShowText(false);
      setShowButton(false); 
      setActiveIndex(swiper.activeIndex);
    }}
    modules={[Autoplay, Pagination, Navigation]}
    className="mySwiper"
  >
    {list.map((product, index) => {
      let images = [];
      try {
        images = product.images ? JSON.parse(product.images) : [];
      } catch (error) {
        console.error("JSON parse error:", error);
      }

      return (
        <SwiperSlide key={index}>
          <ProductCard
            id={product.id}
            name={product.name}
            description={product.description}
            preferences={product.preferences}
            garanty={product.garanty}
            loan={product.loan}
            credit={product.credit}
            new={product.new}
            discount={product.discount}
            technical={product.technical}
            images={images}
          />
        </SwiperSlide>
      );
    })}
  </Swiper>
</div>

      <a className='block md:hidden' href='/products'>
               <div className='flex justify-between items-center rounded-[16px] bg-[#2264DC] font-secondFont text-white w-full mt-[48px] h-[44px] px-[20px]'>
                  <p>Hamısına bax</p>
                  <img className='h-6 w-6' src={about2}></img>
                </div>
               </a>
    </div>
  );
};

export default DiscountProducts;
