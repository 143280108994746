import React from 'react'
import {about1, about2, arashBg3, circles, preferences1, preferences2, preferences3, preferences4 } from '../../../assets/images'

const About = () => { 
  return (
    <>
    <div className='max-w-containerSm md:max-w-container mx-auto mt-[88px] hidden md:mt-[128px] border-[1px] md:flex justify-between gap-3 border-[#2264DC] rounded-[24px] md:rounded-[58px]'>
  <div className='md:w-[680px]'>
    <img className='w-full h-[447px]' src={circles}></img>
  </div>
  <div className='flex flex-col justify-center md:w-[568px] pr-[50px]'>
    <div>
    <p className='text-[#0B0B0B] font-bold text-[24px] md:text-[32px] font-firstFont'>Arash – Sizin gözəlliyiniz və sağlamlığınız üçün etibarlı seçim!</p>
    <p className='text-[#0B0B0B] mt-[16px] font-secondFont'>Arash şirkəti, 1994-cü ildə təsis edilmiş və hazırda Azərbaycanda stomatoloji, kosmetoloji və lazer avadanlıq və məhsullarının satışında liderlik mövqeyindədir. Şirkətimiz, Candela, Ionto Comed, Ultrasun,  Zimmer, Owandy, Digimed, Euronda , DMG , Cavex, Chirana Medical, Septodont,Nexobio, Ajax,  İBSA , Promoitalia, Medytox , Regenyal və sair kimi dünyanın aparıcı istehsalçıları ilə əməkdaşlıq edir.</p>
   <a href='/about'>
   <div className='flex justify-between items-center rounded-[16px] bg-[#2264DC] mt-[30px] text-white w-[203px] h-[44px] px-[20px] font-secondFont'>
      <p>Ətraflı</p>
      <img className='h-6 w-6' src={about2}></img>
    </div>
   </a>
    </div>
  </div>
   </div>


   <div  style={{
    backgroundImage: `url(${arashBg3})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  }}  className='max-w-containerSm mx-auto mt-[88px] md:hidden md:mt-[128px] border-[1px] block border-[#2264DC] rounded-[24px] md:rounded-[58px]'>
   <div className='flex flex-col justify-center w-full px-4 py-[43px]'>
     <div>
     <p className='text-[#0B0B0B] font-bold text-[24px] md:text-[32px] font-firstFont'>Arash – Sizin gözəlliyiniz və sağlamlığınız üçün etibarlı seçim!</p>
     <p className='text-[#0B0B0B] mt-[16px] font-secondFont'>Arash şirkəti, 1994-cü ildə təsis edilmiş və hazırda Azərbaycanda stomatoloji, kosmetoloji və lazer avadanlıq və məhsullarının satışında liderlik mövqeyindədir. Şirkətimiz, Candela, Ionto Comed, Ultrasun,  Zimmer, Owandy, Digimed, Euronda , DMG , Cavex, Chirana Medical, Septodont,Nexobio, Ajax,  İBSA , Promoitalia, Medytox , Regenyal və sair kimi dünyanın aparıcı istehsalçıları ilə əməkdaşlıq edir.</p>
    <a href='/about'>
    <div className='flex justify-between items-center rounded-[16px] bg-[#2264DC] mt-[30px] text-white w-full h-[44px] px-[20px] font-secondFont'>
       <p>Ətraflı</p>
       <img className='h-6 w-6' src={about2}></img>
     </div>
    </a>
     </div> 
   </div>
    </div>
    </>
  ) 
} 
 
export default About