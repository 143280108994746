import React, { useEffect, useState } from 'react'
import { about, about1, about2, about3, contact, facebook, instagram, product1, product2, product3, product4 } from '../../assets/images';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';


 
const Brands = () => {

  
  const navigate = useNavigate()

  const [brands, setBrands] = useState([]);
  const [clientName, setclientName] = useState("");
  const [clientEmail, setclientEmail] = useState("");
  const [clientPhone, setclientPhone] = useState("");
  const [clientMessage, setclientMessage] = useState("");

   useEffect(() => {
      getBrands();
    }, []);
  
    const getBrands = async () => {
      try {
        const response = await axios.get(
          `https://www.adb.az/arash/brands.php`,
          { withCredentials: false }
        );
        setBrands(response.data.data);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };


  const handleSubmitContact = async (e) => {
    e.preventDefault();
  
    let inputs = { name: clientName, email: clientEmail, phone: clientPhone, message: clientMessage };
    
    try {
      console.log(inputs);
      
      // Seçilen ürünleri post isteği ile gönder
      const response = await axios.post("https://www.tanura.az/tanura/contact.php/", inputs);
      
      // Başarılı olduğunda yapılacak işlemler
      toast.success("Form göndərildi");
      
      // Clear form after successful submission
      setclientName('');
      setclientEmail('');
      setclientPhone('');
      setclientMessage('');
      
      navigate('/');
      
    } catch (error) {
      // Hata durumunda yapılacak işlemler
      console.error("Sipariş gönderilirken bir hata oluştu:", error);
      toast.error("Form göndərilərkən xəta baş verdi");
    }
  };

  const { t, i18n } = useTranslation();
  return (
      <div className='bg-[#FCFCFC]'>
        <div className="md:max-w-container max-w-containerSm mx-auto pb-[88px] md:pb-[112px]">
    <div className='pt-[56px] text-[14px] text-firstColor flex items-center font-secondFont'>
      <p>Ana səhifə</p>
      <i className={`fas fa-chevron-right mx-2`} /> 
      <p>Brendlərimiz</p>
    </div>
           <div className='flex justify-center text-center mt-[56px]'>
               <p className="text-[24px] md:text-[32px] font-bold text-center font-firstFont text-firstColor">{t("Brendlərimiz")}</p>
               </div>
   <div className='mt-[64px] grid grid-cols-3 md:grid-cols-6 gap-[75px]'>
    {brands?.map((slide, index) => (
  <img className='md:w-[150px] w-[93px] h-[43px] md:h-[70px]' src={`https://www.adb.az/arash/${slide.image}`}></img>
            ))}
   </div>
  </div>
      </div>
  )
}

export default Brands