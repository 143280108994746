import axios from "axios";
import React, { useEffect, useState } from "react";
import "./dashboard.css";
import { Link, NavLink, useNavigate } from "react-router-dom";
import {logo } from "../../assets/images/index";
import DashboardHeader from "../../Components/DashboardHeader/DashboardHeader";

export default function Dashboard() {

  const [isLoading, setIsLoading] = useState(true)

  const allBrands = [ 
    "set", "ev", "kabin"
  ];
  
  const categories = allBrands.map(brand => ({
    name: brand,
    img: 'logo',  // Buraya markanın logosunun görsel yolunu ekleyin
    path: `/category1/${brand}`  // Markadaki boşlukları '-' ile değiştirip küçük harfe dönüştürerek dinamik path oluşturuluyor
  }));
  
  console.log(categories);
  
  const naviget = useNavigate();
  function logoutSubmit() {
    localStorage.setItem("login", "");
    localStorage.setItem("loginStatus", "Logged out successfully!");
    naviget("/");
  }
  const user = localStorage.getItem("user");

  const [products, setProducts] = useState([]);
  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    try {
      const response = await axios.get(
        `https://www.tanura.az/tanura/dashboard.php`,
        { withCredentials: false }
      );
  

      setProducts(response.data.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  return (
    <>
    <DashboardHeader/>
    <div className="max-w-containerSm md:max-w-container mx-auto py-20">
   <a href="/dashboard/create"> <button  
            type="submit"
             className=" bg-[#223077] text-white px-6 sm:px-7 md:px-8 py-2 rounded-full mb-10">Yeni məhsul yarat</button></a>
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4">
    {categories.map((category1, index) => (
      <NavLink
      key={category1.name}
      to={`/dashboard${category1.path}`}
    >
      <div key={index} className="text-center min-h-20 min-w-20 bg-blue-400 flex justify-center items-center py-2 rounded-md">
    <p className="text-[white]">{category1.name}</p>
      </div>
      </NavLink>
    ))}
  </div>
  </div>
    </>
  );
}