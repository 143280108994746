import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom"; // React Router importları
import PaginatedItems from './PaginatedItems';
import axios from "axios";
import { useTranslation } from "react-i18next";
import { about, filter } from "../../assets/images";

const Products = () => {
  const { t } = useTranslation();
  const [products, setProducts] = useState([]);
  const [brandFilters, setBrandFilters] = useState({});
  const [categoryFilters, setCategoryFilters] = useState({});
  const [brandSearch, setBrandSearch] = useState("");
  const [categorySearch, setCategorySearch] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const navigation = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);




  useEffect(() => {
    getProducts();
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const brandParam = queryParams.get("brands");
    const categoryParam = queryParams.get("categories");

    // URL parametrelerini filtrelerimize yansıt
    if (brandParam) {
      const brandArray = brandParam.split(",");
      setBrandFilters((prev) => {
        const updatedFilters = {};
        brandArray.forEach(brand => {
          updatedFilters[brand] = true;
        });
        return updatedFilters;
      });
    }
    if (categoryParam) {
      const categoryArray = categoryParam.split(",");
      setCategoryFilters((prev) => {
        const updatedFilters = {};
        categoryArray.forEach(category => {
          updatedFilters[category] = true;
        });
        return updatedFilters;
      });
    }
  }, [location.search]);

  const getProducts = async () => {
    try {
      const response = await axios.get(
        `https://www.adb.az/arash/dashboard.php`,
        { withCredentials: false }
      );
      console.log(response.data.data);
      setProducts(response.data.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const handleBrandChange = (brand) => {
    setBrandFilters((prev) => {
      const updatedFilters = {
        ...prev,
        [brand]: !prev[brand],
      };
      updateURLFilters(updatedFilters, categoryFilters);
      return updatedFilters;
    });
  };

  const handleCategoryChange = (category) => {
    setCategoryFilters((prev) => {
      const updatedFilters = {
        ...prev,
        [category]: !prev[category],
      };
      updateURLFilters(brandFilters, updatedFilters);
      return updatedFilters;
    });
  };

  const updateURLFilters = (brandFilters, categoryFilters) => {
    const brandArray = Object.keys(brandFilters).filter((key) => brandFilters[key]);
    const categoryArray = Object.keys(categoryFilters).filter((key) => categoryFilters[key]);

    const queryParams = new URLSearchParams();
    if (brandArray.length > 0) {
      queryParams.set("brands", brandArray.join(","));
    }
    if (categoryArray.length > 0) {
      queryParams.set("categories", categoryArray.join(","));
    }

    // URL'yi güncelle
    navigation({ search: queryParams.toString() });
  };

  const filteredProducts = products.filter(product => {
    const hasSelectedBrand = Object.keys(brandFilters).some(key => brandFilters[key]);
    const matchesBrand = !hasSelectedBrand || brandFilters[product.brand];
    const hasSelectedCategory = Object.keys(categoryFilters).some(key => categoryFilters[key]);
    const matchesCategory = !hasSelectedCategory || categoryFilters[product.category];
    return matchesBrand && matchesCategory;
  });

  const allBrand = [
    "Ajax Medical", "Anthogyr", "Owandy", "Digimed", "Euronda", "ToyeDental", "Chirana Medical", "Meta-Biomed", "Coxo", "Septodont", "DMG", "Cavex", "Tokuyama Dental", "Shofu Dental", "Candela", "Lutronic", "Zimmer", "Promoitalia", "Koru Pharma", "Fidia Farmaceutici", "Medytox", "Regenyal", "IBSA", "Clarteis", "İonto Comed", "Ultrasun", "Classys", "NeoQi", "Euromi", "Vaser", "Endymed"
  ];

  const allCategory = [ 
    "Stomatoloji avadanlıq", "Stomatologiya", "Lazer avadanlığı", "Kosmetologiya", "Kosmetoloji avadanlıq"
  ];

  const filteredBrand = allBrand.filter(brand =>
    brand.toLowerCase().includes(brandSearch.toLowerCase())
  );

  const filteredCategory = allCategory.filter(category =>
    category.toLowerCase().includes(categorySearch.toLowerCase())
  );

  const clearBrandFilters = () => {
    setBrandFilters({});
    updateURLFilters({}, categoryFilters);
  };
  
  const clearCategoryFilters = () => {
    setCategoryFilters({});
    updateURLFilters(brandFilters, {});
  };

  const selectedBrands = Object.keys(brandFilters).filter((key) => brandFilters[key]);
const selectedCategories = Object.keys(categoryFilters).filter((key) => categoryFilters[key]);

// Dinamik başlık oluştur
const dynamicTitle =
  selectedBrands.length > 0 || selectedCategories.length > 0
    ? `Kateqoriyalar: ${[...selectedCategories, ...selectedBrands].join(", ")}`
    : "Məhsullar";


  return (
    <div className='bg-[#FCFCFC]'>
      <div className='max-w-containerSm md:max-w-container mx-auto pb-[88px] md:pb-[112px]'>
        <div className='pt-[56px] text-[14px] text-firstColor flex items-center font-secondFont'>
          <p>Ana səhifə</p>
          <i className={`fas fa-chevron-right mx-2`} /> 
          <p>Məhsullar</p>
        </div>
        {/* <div className="mt-[48px] md:mt-[56px]">
    {(selectedCategories.length === 0 && selectedBrands.length === 0) ? (
      <p className='text-[24px] font-bold font-firstFont text-firstColor mt-[48px] md:mt-[56px]'>
        Məhsullar
      </p>
    ) : (
      <>
        {selectedCategories.length > 0 && (
          <p className='text-[24px] font-bold text-firstColor mt-2 font-firstFont'>
            Kateqoriyalar: {selectedCategories.join(", ")}
          </p>
        )}

        {selectedBrands.length > 0 && (
          <p className='text-[24px] font-bold text-firstColor mt-2 font-firstFont'>
            Brendlər: {selectedBrands.join(", ")}
          </p>
        )}
      </>
    )}
  </div> */}
   <div className="mt-[48px] md:mt-[56px]">
   <p className='text-[24px] font-bold font-firstFont text-firstColor mt-[48px] md:mt-[56px]'>
        Məhsullar
      </p>
  </div>

        <div className='md:flex max-w-containerSm md:max-w-container mx-auto mt-8 justify-between'>
          <button className="md:hidden my-6 border-[1px] border-[#CFCFCF] w-full h-[44px] rounded-2xl px-5 text-[#2264DC] flex justify-between items-center font-secondFont" onClick={() => setIsOpen(!isOpen)}><span>Filter</span> <img className="h-6 w-6" src={filter}></img></button>
          {isOpen && (
  <div className="fixed top-0 left-0 w-full h-full bg-white z-50 p-4 overflow-auto shadow-lg ">
    <button 
      onClick={() => setIsOpen(false)} 
      className="absolute top-4 right-4 text-lg font-bold"
    >
      ✖
    </button>

    <div className="w-full mx-auto mt-8">
      <div className="bg-[#F9FAFB] border border-[#CFCFCF] p-4 rounded-xl">
        <label className='text-[#393941] font-semibold'>Kateqoriyalar</label>
      </div>
      <div className="bg-[#F9FAFB] border border-[#CFCFCF] p-4 rounded-xl mt-2">
              <div className="relative w-full">
  <svg
    className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400"
    xmlns="http://www.w3.org/2000/svg" 
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M21 21l-4.35-4.35m1.85-5.65a7 7 0 11-14 0 7 7 0 0114 0z"
    />
  </svg>
  <input
    type="text"
    placeholder="Axtarın"
    value={categorySearch}
    onChange={(e) => setCategorySearch(e.target.value)}
    className="w-full pl-10 pr-4 border rounded-lg h-[40px] border-[#E7E7E7] focus:outline-none"
  />
</div>

                <div className="flex flex-col mt-2 max-h-48 overflow-y-auto space-y-2 ">
                  {filteredCategory.map((category) => (
                    <div key={category}>
                      <input
                        type="checkbox"
                        id={category}
                        checked={!!categoryFilters[category]}
                        onChange={() => handleCategoryChange(category)}
                        className="accent-[#162D59] h-6 w-6 relative top-[6px]"
                      />
                      <label htmlFor={category} className="ml-2 text-firstColor font-secondFont text-[14px]">{category}</label>
                    </div>
                  ))}
                </div>
                <button onClick={clearCategoryFilters} className="mt-[10px] w-full py-3 border-t-[1px] border-[#CFCFCF] text-[#162D59]">Seçimləri ləğv et</button>
              </div>
      <div className="mt-6">
              <div className="bg-[#F9FAFB] border border-[#CFCFCF] p-4 rounded-xl">
                <label className='text-[#393941] font-semibold'>Brendlər</label>
              </div>

              <div className="bg-[#F9FAFB] border border-[#CFCFCF] p-4 rounded-xl mt-2">
              <div className="relative w-full">
              <svg
    className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M21 21l-4.35-4.35m1.85-5.65a7 7 0 11-14 0 7 7 0 0114 0z"
    />
  </svg>
  <input
    type="text"
    placeholder="Axtarın"
    value={brandSearch}
    onChange={(e) => setBrandSearch(e.target.value)}
    className="w-full pl-10 pr-4 border rounded-lg h-[40px] border-[#E7E7E7] focus:outline-none"
  />
</div>

                <div className="flex flex-col mt-2 max-h-48 overflow-y-auto space-y-2">
                  {filteredBrand.map((brand) => (
                    <div key={brand}>
                      <input
                        type="checkbox"
                        id={brand}
                        checked={!!brandFilters[brand]}
                        onChange={() => handleBrandChange(brand)}
                        className="accent-[#162D59] h-6 w-6 relative top-[6px]"
                      />
                      <label htmlFor={brand} className="ml-2 text-firstColor font-secondFont text-[14px]">{brand}</label>
                    </div>
                  ))}
                </div>
                <button onClick={clearBrandFilters} className="mt-[10px] w-full py-3 border-t-[1px] border-[#CFCFCF] text-[#162D59]">Seçimləri ləğv et</button>
              </div>
            </div>
    </div>
  </div>
)}
          <div className="md:w-[273px] hidden md:block">
            <div>
              <div className="bg-[#F9FAFB] border border-[#CFCFCF] p-4 rounded-xl">
                <label className='text-[#393941] font-semibold'>Kateqoriyalar</label>
              </div>
              <div className="bg-[#F9FAFB] border border-[#CFCFCF] p-4 rounded-xl mt-2">
              <div className="relative w-full">
  <svg
    className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400"
    xmlns="http://www.w3.org/2000/svg" 
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M21 21l-4.35-4.35m1.85-5.65a7 7 0 11-14 0 7 7 0 0114 0z"
    />
  </svg>
  <input
    type="text"
    placeholder="Axtarın"
    value={categorySearch}
    onChange={(e) => setCategorySearch(e.target.value)}
    className="w-full pl-10 pr-4 border rounded-lg h-[40px] border-[#E7E7E7] focus:outline-none"
  />
</div>

                <div className="flex flex-col mt-2 max-h-48 overflow-y-auto space-y-2 ">
                  {filteredCategory.map((category) => (
                    <div key={category}>
                      <input
                        type="checkbox"
                        id={category}
                        checked={!!categoryFilters[category]}
                        onChange={() => handleCategoryChange(category)}
                        className="accent-[#162D59] h-6 w-6 relative top-[6px]"
                      />
                      <label htmlFor={category} className="ml-2 text-firstColor font-secondFont text-[14px]">{category}</label>
                    </div>
                  ))}
                </div>
                <button onClick={clearCategoryFilters} className="mt-[10px] w-full py-3 border-t-[1px] border-[#CFCFCF] text-[#162D59]">Seçimləri ləğv et</button>
              </div>
            </div>

            <div className="mt-6">
              <div className="bg-[#F9FAFB] border border-[#CFCFCF] p-4 rounded-xl">
                <label className='text-[#393941] font-semibold'>Brendlər</label>
              </div>

              <div className="bg-[#F9FAFB] border border-[#CFCFCF] p-4 rounded-xl mt-2">
              <div className="relative w-full">
  <svg
    className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M21 21l-4.35-4.35m1.85-5.65a7 7 0 11-14 0 7 7 0 0114 0z"
    />
  </svg>
  <input
    type="text"
    placeholder="Axtarın"
    value={brandSearch}
    onChange={(e) => setBrandSearch(e.target.value)}
    className="w-full pl-10 pr-4 border rounded-lg h-[40px] border-[#E7E7E7] focus:outline-none"
  />
</div>

                <div className="flex flex-col mt-2 max-h-48 overflow-y-auto space-y-2">
                  {filteredBrand.map((brand) => (
                    <div key={brand}>
                      <input
                        type="checkbox"
                        id={brand}
                        checked={!!brandFilters[brand]}
                        onChange={() => handleBrandChange(brand)}
                        className="accent-[#162D59] h-6 w-6 relative top-[6px]"
                      />
                      <label htmlFor={brand} className="ml-2 text-firstColor font-secondFont text-[14px]">{brand}</label>
                    </div>
                  ))}
                </div>
                <button onClick={clearBrandFilters} className="mt-[10px] w-full py-3 border-t-[1px] border-[#CFCFCF] text-[#162D59]">Seçimləri ləğv et</button>
              </div>
            </div>
          </div>

          <div className="md:w-[985px]">
            {isLoading ? <p className="text-white">Loading...</p> :
              <PaginatedItems itemsPerPage={12} items={filteredProducts} />
            }
          </div>
        </div>
       
      </div>
    </div>
  );
};

export default Products;
