import React, { useEffect, useState } from 'react';
import { close, logo, magnifier } from '../../assets/images';
import axios from 'axios';
import PaginatedItems from './PaginatedItems';
import PaginatedItemsMobile from './PaginatedItemsMobile';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Header = () => {
  const [products, setProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [isHovered, setIsHovered] = useState(false); // Hover durumunu kontrol eden state
      const [isProductsOpen, setIsProductsOpen] = useState(false); // "Məhsullar" alt menüsünü kontrol eden state

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const toggleSearch = () => {
    setIsSearchVisible(!isSearchVisible);
    setSearchQuery('');
  };

  const toggleProductsMenu = () => {
    setIsProductsOpen(!isProductsOpen);
  };

  const toggleSearch2 = () => {
    setIsSearchVisible(!isSearchVisible);
    setSearchQuery("")
  };

    const { t, i18n } = useTranslation();

     const handleClickOutside = (event) => {
          if (!event.target.closest('.products-menu') && !event.target.closest('.products-link')) {
            setIsProductsOpen(false);
          }
        };
      
        // Add event listener to close the menu if clicked outside
        useEffect(() => {
          document.addEventListener('click', handleClickOutside);
          return () => {
            document.removeEventListener('click', handleClickOutside);
          };
        }, []);


  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    try {
      const response = await axios.get(
        `https://www.adb.az/arash/dashboard.php`,
        { withCredentials: false }
      );
      setProducts(response.data.data);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

    const location = useLocation(); // Get current route

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const isActiveLink = (path) => location.pathname === path;

  const filteredProducts = searchQuery
    ? products.filter((product) =>
        product.name.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : [];

      const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);
      const toggleHamburgerMenu = () => {
        setIsHamburgerMenuOpen(!isHamburgerMenuOpen);
        setSearchQuery('');
      };
      

  return (
    <>
      <div className="max-w-containerSm md:max-w-container mx-auto py-[19px] h-[80px] flex justify-between items-center relative font-secondFont">

        <div className='flex items-center md:hidden'>       
          <a href='/'> <img className='w-[92px] h-[43px]' src={logo}></img></a>
        </div>

                <button className="md:hidden focus:outline-none" onClick={toggleHamburgerMenu}>
                <i className="fa-solid fa-bars text-[24px] text-[black]"></i>
               
              </button>
        {/* Logo ve Menü sadece search kapalıyken görünecek */}
        {!isSearchVisible && (
          <>
            <div className="hidden md:block">
              <a className="hidden md:block" href="/">
                <img alt="logo" height={43} width={92} src={logo} />
              </a>
            </div>

            <div className='md:flex items-center w-[760px] hidden relative'>
          <nav>
  <ul className="flex justify-between text-[#0B0B0B]">
  <a className={`mr-[48px] py-[19px] ${isActiveLink('/about') ? 'text-[#223077] ' : 'text-[#0B0B0B]'}`} href="/about">
      <li>{t("Haqqımızda")}</li> 
    </a>
    <a href="#"
                onMouseEnter={handleMouseEnter} // Hover olduğunda alt menüyü göster
                onMouseLeave={handleMouseLeave} // Hover dışı olduğunda alt menüyü gizle
                onClick={toggleProductsMenu} className={`mr-[48px] py-[19px] products-link ${isActiveLink('/products') ? 'text-[#223077] ' : 'text-[#0B0B0B]'}`}>
      <li>{t("Məhsullar")}</li>
    </a>
    <a className={`mr-[48px] py-[19px] ${isActiveLink('/service') ? 'text-[#223077] ' : 'text-[#0B0B0B]'}`} href="/service">
      <li>{t("Servis")}</li>
    </a>

    <a className={`mr-[48px] py-[19px] ${isActiveLink('/kredit') ? 'text-[#223077] ' : 'text-[#0B0B0B]'}`} href="/kredit">
      <li>{t("Kredit")}</li>
    </a>
    <a className={`mr-[48px] py-[19px] ${isActiveLink('/icare') ? 'text-[#223077] ' : 'text-[#0B0B0B]'}`} href="/icare">
      <li>{t("İcarə")}</li>
    </a>
    <a className={`mr-[48px] py-[19px] ${isActiveLink('/kampaniya') ? 'text-[#223077] ' : 'text-[#0B0B0B]'}`} href="/kampaniya">
      <li>{t("Kampaniyalar")}</li>
    </a>

    <a className={` py-[19px] ${isActiveLink('/contact') ? 'text-[#223077] ' : 'text-[#0B0B0B]'}`}  href="/contact">
      <li>{t("Əlaqə")}</li>
    </a>
  </ul>
</nav>

          </div>
          </>
        )}

        {/* Search Input - Ortalanmış */}
        {isSearchVisible ? (
          <div className="absolute w-full justify-center hidden md:flex">
            <div className="relative">
            <button className="absolute top-3 left-4 text-white">
            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M11.5 2.75C6.66751 2.75 2.75 6.66751 2.75 11.5C2.75 16.3325 6.66751 20.25 11.5 20.25C16.3325 20.25 20.25 16.3325 20.25 11.5C20.25 6.66751 16.3325 2.75 11.5 2.75ZM1.25 11.5C1.25 5.83908 5.83908 1.25 11.5 1.25C17.1609 1.25 21.75 5.83908 21.75 11.5C21.75 14.0605 20.8111 16.4017 19.2589 18.1982L22.5303 21.4697C22.8232 21.7626 22.8232 22.2374 22.5303 22.5303C22.2374 22.8232 21.7626 22.8232 21.4697 22.5303L18.1982 19.2589C16.4017 20.8111 14.0605 21.75 11.5 21.75C5.83908 21.75 1.25 17.1609 1.25 11.5Z" fill="black"/>
</svg>
              </button>
              <input
                type="text"
                value={searchQuery}
                onChange={handleSearchChange}
                placeholder="Axtar..."
                className="rounded-[50px] w-[300px] md:w-[755px] h-[44px] outline-none focus:ring-0 focus:outline-none border-[#C3C3C3] border-2 px-12 text-firstColor"
              />
              <button onClick={toggleSearch} className="absolute top-[11px] right-4 text-white">
              <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="black"
        width="24"
        height="24"
      >
        <path d="M6 6L18 18M18 6L6 18" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
              </button>
            </div>
          </div>
        ) : (
          <button onClick={toggleSearch} className="text-xl text-white hidden md:block">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M11.5 2.75C6.66751 2.75 2.75 6.66751 2.75 11.5C2.75 16.3325 6.66751 20.25 11.5 20.25C16.3325 20.25 20.25 16.3325 20.25 11.5C20.25 6.66751 16.3325 2.75 11.5 2.75ZM1.25 11.5C1.25 5.83908 5.83908 1.25 11.5 1.25C17.1609 1.25 21.75 5.83908 21.75 11.5C21.75 14.0605 20.8111 16.4017 19.2589 18.1982L22.5303 21.4697C22.8232 21.7626 22.8232 22.2374 22.5303 22.5303C22.2374 22.8232 21.7626 22.8232 21.4697 22.5303L18.1982 19.2589C16.4017 20.8111 14.0605 21.75 11.5 21.75C5.83908 21.75 1.25 17.1609 1.25 11.5Z" fill="black"/>
</svg>
          </button>
        )}
      </div>

      {/* Arama Sonuçları */}
      {isSearchVisible && searchQuery && (
        <div className="absolute top-0 left-0 w-full bg-white z-20 mt-[120px] hidden md:block">
          {isLoading ? (
            <p className="text-black text-center">Loading...</p>
          ) : (
            <PaginatedItems itemsPerPage={12} items={filteredProducts} />
          )}
        </div>
      )}
      {searchQuery && ( 
        <div className="absolute top-0 left-0 w-full bg-white mt-[140px] md:hidden z-50">
          {isLoading ? (
            <p className="text-black text-center">Loading...</p>
          ) : (
            <PaginatedItemsMobile itemsPerPage={12} items={filteredProducts} />
          )}
        </div>
      )}

      
      {isHamburgerMenuOpen && (
        <div className={`fixed inset-y-0 bg-[white] left-0 transform ${isHamburgerMenuOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out w-full z-40 shadow-lg`}>
                 <div className="flex flex-col items-start max-w-containerSm mx-auto mt-4 w-full ">
                 <div className="flex justify-between w-full">
                 <a className='block' href='/'> <img src={logo} alt="Logo" className="w-[80px]" /></a>

<button onClick={toggleHamburgerMenu}>
<svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="black"
        width="24"
        height="24"
      >
        <path d="M6 6L18 18M18 6L6 18" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
</button>
 
       </div>
  <div className="items-center justify-center h-screen w-full mt-4">
    <div className="gap-4 max-w-containerSm flex flex-col font-secondFont">
      <div className="relative mt-3">
            <button className="absolute top-3 left-4 text-white">
            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M11.5 2.75C6.66751 2.75 2.75 6.66751 2.75 11.5C2.75 16.3325 6.66751 20.25 11.5 20.25C16.3325 20.25 20.25 16.3325 20.25 11.5C20.25 6.66751 16.3325 2.75 11.5 2.75ZM1.25 11.5C1.25 5.83908 5.83908 1.25 11.5 1.25C17.1609 1.25 21.75 5.83908 21.75 11.5C21.75 14.0605 20.8111 16.4017 19.2589 18.1982L22.5303 21.4697C22.8232 21.7626 22.8232 22.2374 22.5303 22.5303C22.2374 22.8232 21.7626 22.8232 21.4697 22.5303L18.1982 19.2589C16.4017 20.8111 14.0605 21.75 11.5 21.75C5.83908 21.75 1.25 17.1609 1.25 11.5Z" fill="black"/>
</svg>
              </button>
              <input
                type="text"
                value={searchQuery}
                onChange={handleSearchChange}
                placeholder="Axtar..."
                className="rounded-[50px] w-full md:w-[755px] outline-none focus:ring-0 h-[44px] focus:outline-none border-[#C3C3C3] border-2 px-12 text-firstColork"
              />
              {/* <button onClick={toggleSearch} className="absolute top-3 right-20 text-white">
                <img className='w-4 h-4' src={close}></img>
              </button> */}
            </div>
      <a className={`text-base text-left mt-5 font-normal ${isActiveLink('/') ? 'text-[#223077] ' : 'text-[black]'}`} href="/"> {t("Əsas səhifə")}</a>
       <a href="/about" className={`text-base text-left font-normal ${isActiveLink('/about') ? 'text-[#223077]' : 'text-[black]'}`}>{t("Haqqımızda")}</a>
              <a    href="/products"
                 className={`text-base text-left font-normal ${isActiveLink('/products') ? 'text-[#223077]' : 'text-[black]'}`}>{t("Məhsullar")}</a>
              <a href="/service" className={`text-base text-left font-normal ${isActiveLink('/service') ? 'text-[#223077]' : 'text-[black]'}`}>{t("Servis")}</a>
              <a href="/kredit" className={`text-base text-left font-normal ${isActiveLink('/kredit') ? 'text-[#223077]' : 'text-[black]'}`}>{t("Kredit")}</a>
              <a href="/icare" className={`text-base text-left font-normal ${isActiveLink('/icare') ? 'text-[#223077]' : 'text-[black]'}`}>{t("İcarə")}</a>
              <a href="/kampaniya" className={`text-base text-left font-normal ${isActiveLink('/kampaniya') ? 'text-[#223077]' : 'text-[black]'}`}>{t("Kampaniya")}</a>
              <a href="/contact" className={`text-base text-left font-normal ${isActiveLink('/contact') ? 'text-[#223077]' : 'text-[black]'}`}>{t("Əlaqə")}</a>
    </div>
  </div>
</div>

        </div>
      )} 
      <div
        className={`absolute left-0 w-full bg-[white] products-menu z-10 ${isHovered || isProductsOpen ? 'border-t-[1px] border-[#CFCFCF]' : ''}`}
        onMouseEnter={handleMouseEnter} // Alt menüye hover yapılınca kapanmasın
        onMouseLeave={handleMouseLeave} // Alt menüden çıkınca kapanmasın
      > 
        {(isHovered || isProductsOpen) && (
          <div className='max-w-container mx-auto flex justify-between py-[32px] font-secondFont text-firstColor text-[14px] '>
             <div className="w-[193px]">
        <a href='/products?categories=Stomatoloji+avadanlıq' className="font-bold text-[14px] font-firstFont text-firstColor">Stomatoloji avadanlıq</a>
        <ul className='space-y-3 mt-6 flex flex-col'>
          <a href='/products?brands=Owandy&categories=Stomatoloji+avadanlıq'>Owandy</a>
          <a href='/products?brands=Anthogyr&categories=Stomatoloji+avadanlıq'>Anthogyr</a>
          <a href='/products?brands=Euronda&categories=Stomatoloji+avadanlıq'>Euronda</a>
          <a href='/products?brands=Digimed&categories=Stomatoloji+avadanlıq'>Digimed</a>
          <a href='/products?brands=Ajax+Medical&categories=Stomatoloji+avadanlıq'>Ajax Medical</a>
          <a href='/products?brands=Chirana+Medical&categories=Stomatoloji+avadanlıq'>Chirana Medical</a>
          <a href='/products?brands=ToyeDental&categories=Stomatoloji+avadanlıq'>ToyeDental</a>
          <a href='/products?brands=Coxo&categories=Stomatoloji+avadanlıq'>Coxo</a>
        </ul>
      </div>
      <div className="w-[193px]">
        <a href='/products?categories=Stomatologiya' className="font-bold text-[14px] font-firstFont text-firstColor">Stomatologiya</a>
        <ul className='space-y-3 mt-6 flex flex-col'>
          <a href='/products?brands=Meta-Biomed&categories=Stomatologiya'>Meta-Biomed</a>
          <a href='/products?brands=Tokuyama+Dental&categories=Stomatologiya'>Tokuyama Dental</a>
          <a href='/products?brands=Septodont&categories=Stomatologiya'>Septodont</a>
          <a href='/products?brands=DMG&categories=Stomatologiya'>DMG</a>
          <a href='/products?brands=Cavex&categories=Stomatologiya'>Cavex</a>
          <a href='/products?brands=Shofu+Dental&categories=Stomatologiya'>Shofu Dental</a>
        </ul>
      </div>
      <div  className="w-[193px]">
        <a href='/products?categories=Lazer+avadanlığı' className="font-bold text-[14px] font-firstFont text-firstColor">Lazer avadanlığı</a>
        <ul className='space-y-3 mt-6 flex flex-col'>
          <a href='/products?brands=Candela&categories=Lazer avadanlığı'>Candela</a>
          <a href='/products?brands=Lutronic&categories=Lazer avadanlığı'>Lutronic</a>
          <a href='/products?brands=Zimmer&categories=Lazer avadanlığı'>Zimmer</a>
        </ul>
      </div>
      <div  className="w-[193px]">
        <a href='/products?categories=Kosmetologiya' className="font-bold text-[14px] font-firstFont text-firstColor">Kosmetologiya</a>
        <ul className='space-y-3 mt-6 flex flex-col'>
          <a href='/products?brands=Promoitalia&categories=Kosmetologiya'>Promoitalia</a>
          <a href='/products?brands=Koru+Pharma&categories=Kosmetologiya'>Koru Pharma</a>
          <a href='/products?brands=Fidia+Farmaceutici&categories=Kosmetologiya'>Fidia Farmaceutici</a>
          <a href='/products?brands=Medytox&categories=Kosmetologiya'>Medytox</a>
          <a href='/products?brands=Regenyal&categories=Kosmetologiya'>Regenyal</a>
          <a href='/products?brands=IBSA&categories=Kosmetologiya'>IBSA</a>
          <a href='/products?brands=Clarteis&categories=Kosmetologiya'>Clarteis</a>
        </ul>
      </div>
      <div  className="w-[193px]">
        <a href='/products?categories=Kosmetoloji avadanlıq' className="font-bold text-[14px] font-firstFont text-firstColor">Kosmetoloji avadanlıq</a>
        <ul className='space-y-3 mt-6 flex flex-col'>
          <a href='/products?brands=İonto+Comed&categories=Kosmetoloji+avadanlıq'>İonto Comed</a>
          <a href='/products?brands=Ultrasun&categories=Kosmetoloji+avadanlıq'>Ultrasun</a>
          <a href='/products?brands=Classys&categories=Kosmetoloji+avadanlıq'>Classys</a>
          <a href='/products?brands=NeoQi&categories=Kosmetoloji+avadanlıq'>NeoQi</a>
          <a href='/products?brands=Euromi&categories=Kosmetoloji+avadanlıq'>Euromi</a>
          <a href='/products?brands=Vaser&categories=Kosmetoloji+avadanlıq'>Vaser</a>
          <a href='/products?brands=Endymed&categories=Kosmetoloji+avadanlıq'>Endymed</a>
        </ul>
      </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Header;
