import React, { useEffect, useState } from "react";
import { footer1, footer10, footer2, footer3, footer4, footer5, footer6, footer7, footer8, footer9 } from '../../assets/images';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
 
const Contact = () => {
    const { t } = useTranslation();

        const [item, setItem] = useState([]);
            const [isLoading, setIsLoading] = useState(true);

    const [formData, setFormData] = useState({
        name: '',
        surname: '',
        phone: '',
        reason: ''
    });
    const [responseMessage, setResponseMessage] = useState({ text: '', isError: false });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async (event) => {
      event.preventDefault();
    
      // Form validation
      if (!formData.name || !formData.surname || !formData.phone || !formData.reason) {
        setResponseMessage({ text: 'Lütfen tüm alanları doldurunuz.', isError: true });
        return;
      }
    
      try {
        // Backend'inizin beklediği form-data formatında gönderim
        const formDataToSend = new FormData();
        formDataToSend.append('name', formData.name);
        formDataToSend.append('surname', formData.surname);
        formDataToSend.append('phone', formData.phone);
        formDataToSend.append('reason', formData.reason);
    
        const response = await axios.post(
          'https://adb.az/arash/contact.php',
          formDataToSend,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );
    
        // Backend yanıtını işleme
        const responseData = response.data;
        if (responseData.status === 1) {
          setResponseMessage({ text: responseData.message || 'Veri başarıyla gönderildi!', isError: false });
          // Formu temizle
          setFormData({
            name: '',
            surname: '',
            phone: '',
            reason: ''
          });
        } else {
          setResponseMessage({ 
            text: responseData.message || 'İşlem başarısız oldu', 
            isError: true 
          });
        }
      } catch (error) {
        console.error('API Hatası:', error);
        let errorMessage = 'Sunucuya bağlanırken bir hata oluştu';
        
        // Sunucudan gelen hatayı işleme
        if (error.response) {
          errorMessage = error.response.data?.message || 
                         (error.response.data ? JSON.stringify(error.response.data) : error.message);
        }
        
        setResponseMessage({ 
          text: errorMessage, 
          isError: true 
        });
      }
    };


      useEffect(() => {
            getProducts();
          }, []);
        
          const getProducts = async () => {
            try {
              const response = await axios.get(
                `https://www.adb.az/arash/settings.php`,
                { withCredentials: false }
              );
              console.log(response.data.data);
              setItem(response.data.data[0]);
              setIsLoading(false);
            } catch (error) {
              console.error("Error fetching products:", error);
            }
          };

    return (
        <div className='bg-[#FCFCFC]'>
            <div className='max-w-containerSm md:max-w-container mx-auto pb-[88px] md:pb-[112px]'>
                <div className='pt-[56px] text-[14px] text-firstColor flex items-center font-secondFont'>
                    <p>Ana səhifə</p>
                    <i className={`fas fa-chevron-right mx-2`} /> 
                    <p>Əlaqə</p>
                </div>
                
                <div className='mt-[56px] md:flex justify-between'>
                    <div className='md:w-[279px] text-firstColor'>
                        <ul className='space-y-6'>
                            <li className='flex'><img className='h-6 w-6 mr-2' src={footer6} alt="address"/><p className='font-secondFont'><span className='font-bold text-[18px] font-firstFont'>Ünvan:</span> {item.location}</p></li>
                            <li className='flex'><img className='h-6 w-6 mr-2' src={footer7} alt="postal"/><p className='font-secondFont'><span className='font-bold text-[18px] font-firstFont'>Poçt indeksi:</span> {item.postal}</p></li>
                            <li className='flex'><img className='h-6 w-6 mr-2' src={footer8} alt="phone"/><p className='font-secondFont'><span className='font-bold text-[18px] font-firstFont'>Telefon:</span> {item.phone}</p></li>
                            <li className='flex'><img className='h-6 w-6 mr-2' src={footer9} alt="mobile"/><p className='font-secondFont'><span className='font-bold text-[18px] font-firstFont'>Mobil:</span> {item.mobile}</p></li>
                            <li className='flex'><img className='h-6 w-6 mr-2' src={footer10} alt="email"/><p className='font-secondFont'><span className='font-bold text-[18px] font-firstFont'>E-mail:</span> {item.email}</p></li>
                        </ul>
                        
                        <div className='flex gap-3 mt-[48px]'>
                            {[
                                { icon: footer1, url: item.instagram },
                                { icon: footer2, url: item.youtube },
                                { icon: footer3, url: item.facebook },
                                { icon: footer4, url: item.linkedin },
                                { icon: footer5, url: item.tiktok }
                            ].map((social, index) => (
                                <div key={index}>
                                    <a target='_blank' href={social.url} rel="noopener noreferrer">
                                        <img className='w-11 h-11' src={social.icon} alt={`social-${index}`}/>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className='md:w-[628px] mt-[48px] md:mt-0'>
                        <form className="font-secondFont" onSubmit={handleSubmit}>
                            <p className="text-thirdColor text-[14px]">Ad</p>
                            <input
                                name="name"
                                className="rounded-lg h-[56px] w-full p-4 border-[1px] border-[#CFCFCF] mt-2 outline-none focus:ring-0 focus:outline-none"
                                placeholder="Type here"
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />

                            <p className="text-thirdColor text-[14px] mt-5">Soyad</p>
                            <input
                                name="surname"
                                className="rounded-lg h-[56px] w-full p-4 border-[1px] border-[#CFCFCF] mt-2 outline-none focus:ring-0 focus:outline-none"
                                placeholder="Type here"
                                value={formData.surname}
                                onChange={handleChange}
                                required
                            />

                            <p className="text-thirdColor text-[14px] mt-5">Nömrə</p>
                            <input
                                name="phone"
                                className="rounded-lg h-[56px] w-full p-4 border-[1px] border-[#CFCFCF] mt-2 outline-none focus:ring-0 focus:outline-none"
                                placeholder="Type here"
                                value={formData.phone}
                                onChange={handleChange}
                                required
                            />

                            <p className="text-thirdColor text-[14px] mt-5">Müraciət səbəbi</p>
                            <select
                                name="reason"
                                className="rounded-lg h-[56px] w-full p-4 border-[1px] border-[#CFCFCF] mt-2 outline-none focus:ring-0 focus:outline-none bg-white"
                                value={formData.reason}
                                onChange={handleChange}
                                required
                            >
                                <option value="">Seç</option>
                                <option value="kredit">Kredit</option>
                                <option value="icare">İcarə</option>
                                <option value="servis">Servis</option>
                                <option value="kampaniya">Kampaniya</option>
                                <option value="nagd">Nağd alış</option>
                            </select>

                            <button
                                type="submit"
                                className="w-full h-[44px] rounded-2xl bg-[#2264DC] font-secondFont text-white mt-[48px] hover:bg-[#1a52b5] transition-colors"
                            >
                                Göndər
                            </button>

                            {responseMessage.text && (
                                <p className={`mt-4 text-center ${responseMessage.isError ? 'text-red-500' : 'text-green-500'}`}>
                                    {responseMessage.text}
                                </p>
                            )}
                        </form>
                    </div>
                </div>
                
                <iframe 
                    className='w-full mt-[88px] md:mt-[112px] h-[313px] md:h-[459px]' 
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d194473.18588939894!2d49.8549596!3d40.394592499999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40307d6bd6211cf9%3A0x343f6b5e7ae56c6b!2zQmFrxLE!5e0!3m2!1saz!2saz!4v1742840827295!5m2!1saz!2saz" 
                    title="Harita"
                    allowFullScreen 
                    loading="lazy" 
                    referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
            </div>
        </div>
    );
};

export default Contact;