import React, { useEffect, useState } from 'react';
import { logo } from '../../assets/images';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import PaginatedItems from './PaginatedItems';
import { useTranslation, initReactI18next } from "react-i18next";
import i18n from "i18next";
import enTranslation from "../i18n/en/translation.json";
import azTranslation from "../i18n/az/translation.json";
import rusTranslation from "../i18n/rus/translation.json";
import { NavLink } from "react-router-dom";



const languageOptions = [
  { value: "az", label: "AZ",  },
  { value: "rus", label: "RU", },
];

i18n.use(initReactI18next).init({
  resources: {
      az: { translation: azTranslation },
      rus: { translation: rusTranslation },
  },
  lng: localStorage.getItem("language") || "az",
  fallbackLng: "az",
  interpolation: { escapeValue: false },
});

const WithoutSearchLast = () => {

    const [products, setProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(true);
    const [isHovered, setIsHovered] = useState(false); // Hover durumunu kontrol eden state
  const [isSearchVisible, setIsSearchVisible] = useState(false);
    const [isProductsOpen, setIsProductsOpen] = useState(false); // "Məhsullar" alt menüsünü kontrol eden state


  const toggleSearch = () => {
    setIsSearchVisible(!isSearchVisible);
    setSearchQuery("")
  };

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    try {
      const response = await axios.get(
        `https://www.adb.az/arash/dashboard.php`,
        { withCredentials: false }
      );
      setProducts(response.data.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredProducts = searchQuery
    ? products.filter((product) =>
        product.name.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : [];


  const location = useLocation(); // Get current route

  // Function to check if the current path is active
  const isActiveLink = (path) => location.pathname === path;

  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);
  const toggleHamburgerMenu = () => setIsHamburgerMenuOpen(!isHamburgerMenuOpen);
  


  const { t, i18n } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(languageOptions.find(lang => lang.value === i18n.language));

  useEffect(() => {
      const savedLanguage = localStorage.getItem("language");
      if (savedLanguage) {
          const language = languageOptions.find(lang => lang.value === savedLanguage);
          if (language) {
              setSelectedLanguage(language);
              i18n.changeLanguage(language.value);
          }
      }
  }, []);

  const toggleDropdown = () => {
      setIsOpen(!isOpen);
  };

  const handleLanguageChange = (language) => {
      setSelectedLanguage(language);
      setIsOpen(false);
      i18n.changeLanguage(language.value);
      localStorage.setItem("language", language.value);
  };

   const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);
  
    // Handle click outside of the menu to close it
    const handleClickOutside = (event) => {
      if (!event.target.closest('.products-menu') && !event.target.closest('.products-link')) {
        setIsProductsOpen(false);
      }
    };
  
    // Add event listener to close the menu if clicked outside
    useEffect(() => {
      document.addEventListener('click', handleClickOutside);
      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    }, []);
  
    // Function to toggle "Məhsullar" menu when clicked
    const toggleProductsMenu = () => {
      setIsProductsOpen(!isProductsOpen);
    };


  return (
    <>
        <div className='max-w-containerSm md:max-w-container mx-auto flex flex-row-reverse bg-[green] md:flex-row justify-between font-secondFont'>
        <button className="md:hidden focus:outline-none  py-[19px]" onClick={toggleHamburgerMenu}>
        <i className="fa-solid fa-bars text-[24px] text-[black]"></i>
        {isHamburgerMenuOpen && (
        <div className={`fixed inset-y-0  bg-[white] left-0 transform ${isHamburgerMenuOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out w-full z-50 shadow-lg`}>
                 <div className="flex">

          <button className="absolute top-5 right-5 p-2 rounded" onClick={toggleHamburgerMenu}>
          <i className="fa-solid fa-xmark text-[32px] text-[black]"></i>
          </button>
          
                 </div> 
          <div className="flex flex-col items-start p-8 w-full ">
            <div className="items-center justify-center sm:justify-end space-x-2 space-y-2 sm:space-y-0 h-screen"> 
            <div className="gap-4 max-w-containerSm flex flex-col"> 
       <a href='/'> <img src={logo} alt="Logo" className="w-[109px] mb-5" /></a>
       <a className={`text-base text-left font-normal ${isActiveLink('/') ? 'text-[#223077] ' : 'text-[black]'}`} href="/"> {t("Əsas səhifə")}</a>
       <a href="/about" className={`text-base text-left font-normal ${isActiveLink('/about') ? 'text-[#223077]' : 'text-[black]'}`}>{t("Haqqımızda")}</a>
              <a    href="/products"
                 className={`text-base text-left font-normal ${isActiveLink('/products') ? 'text-[#223077]' : 'text-[black]'}`}>{t("Məhsullar")}</a>
              <a href="/service" className={`text-base text-left font-normal ${isActiveLink('/service') ? 'text-[#223077]' : 'text-[black]'}`}>{t("Servis")}</a>
              <a href="/kredit" className={`text-base text-left font-normal ${isActiveLink('/kredit') ? 'text-[#223077]' : 'text-[black]'}`}>{t("Kredit")}</a>
              <a href="/icare" className={`text-base text-left font-normal ${isActiveLink('/icare') ? 'text-[#223077]' : 'text-[black]'}`}>{t("İcarə")}</a>
              <a href="/kampaniya" className={`text-base text-left font-normal ${isActiveLink('/kampaniya') ? 'text-[#223077]' : 'text-[black]'}`}>{t("Kampaniya")}</a>
              <a href="/contact" className={`text-base text-left font-normal ${isActiveLink('/contact') ? 'text-[#223077]' : 'text-[black]'}`}>{t("Əlaqə")}</a>
    </div>

          </div>
          </div>
        </div> 
      )} 
      </button>
          <div className='py-[19px]'>
            <a href='/'><img alt='logo' className='h-[43px] w-[92px]' src={logo}></img></a>
          </div>
          <div className='md:flex items-center w-[740px] hidden relative'>
          <nav>
  <ul className="flex justify-between text-[#0B0B0B]">
  <a className={`mr-[48px] py-[19px] ${isActiveLink('/about') ? 'text-[#223077] ' : 'text-[#0B0B0B]'}`} href="/about">
      <li>{t("Haqqımızda")}</li> 
    </a>
    <a href="#"
                onMouseEnter={handleMouseEnter} // Hover olduğunda alt menüyü göster
                onMouseLeave={handleMouseLeave} // Hover dışı olduğunda alt menüyü gizle
                onClick={toggleProductsMenu} className={`mr-[48px] py-[19px] products-link ${isActiveLink('/products') ? 'text-[#223077] ' : 'text-[#0B0B0B]'}`}>
      <li>{t("Məhsullar")}</li>
    </a>
    <a className={`mr-[48px] py-[19px] ${isActiveLink('/service') ? 'text-[#223077] ' : 'text-[#0B0B0B]'}`} href="/service">
      <li>{t("Servis")}</li>
    </a>

    <a className={`mr-[48px] py-[19px] ${isActiveLink('/kredit') ? 'text-[#223077] ' : 'text-[#0B0B0B]'}`} href="/kredit">
      <li>{t("Kredit")}</li>
    </a>
    <a className={`mr-[48px] py-[19px] ${isActiveLink('/icare') ? 'text-[#223077] ' : 'text-[#0B0B0B]'}`} href="/icare">
      <li>{t("İcarə")}</li>
    </a>
    <a className={`mr-[48px] py-[19px] ${isActiveLink('/kampaniya') ? 'text-[#223077] ' : 'text-[#0B0B0B]'}`} href="/kampaniya">
      <li>{t("Kampaniyalar")}</li>
    </a>

    <a className={` py-[19px] ${isActiveLink('/contact') ? 'text-[#223077] ' : 'text-[#0B0B0B]'}`}  href="/contact">
      <li>{t("Əlaqə")}</li>
    </a>
  </ul>
</nav>

          </div>
          <div className=" items-center justify-end relative hidden md:flex  py-[19px]">
          {/* Arama Butonu ve Overlay */}
          {isSearchVisible ? (
            <div className="absolute inset-0 bg-black bg-opacity-50 z-10 flex justify-center items-center">
              <div className="relative right-20 md:right-0">
                <input
                  type="text"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  placeholder="Axtar..."
                  className="p-2 rounded-2xl w-[215px] bg-[white] border-[#595959] border-2 text-black"
                />
                <button onClick={toggleSearch} className="absolute top-2 right-2 text-black">
                  <i className="fa-solid fa-xmark text-[24px]"></i>
                </button>
              </div>
            </div>
          ) : (
            <button onClick={toggleSearch} className="text-xl text-black mr-4">
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M11.5 2.75C6.66751 2.75 2.75 6.66751 2.75 11.5C2.75 16.3325 6.66751 20.25 11.5 20.25C16.3325 20.25 20.25 16.3325 20.25 11.5C20.25 6.66751 16.3325 2.75 11.5 2.75ZM1.25 11.5C1.25 5.83908 5.83908 1.25 11.5 1.25C17.1609 1.25 21.75 5.83908 21.75 11.5C21.75 14.0605 20.8111 16.4017 19.2589 18.1982L22.5303 21.4697C22.8232 21.7626 22.8232 22.2374 22.5303 22.5303C22.2374 22.8232 21.7626 22.8232 21.4697 22.5303L18.1982 19.2589C16.4017 20.8111 14.0605 21.75 11.5 21.75C5.83908 21.75 1.25 17.1609 1.25 11.5Z" fill="black"/>
</svg>
            </button>
          )}

          {/* Sepet Ikonu */}
          {/* <div className="relative inline-flex items-center space-x-4 mt-4 sm:mt-0 sm:ml-0 flex-wrap sm:flex-nowrap">
            <button
              onClick={toggleDropdown}
              className={`flex items-center space-x-2 px-4 py-2  rounded-full border border-black text-[#B8B8B8]`}
            >
              <span>{selectedLanguage.label}</span>
              <i className={`fas fa-chevron-down text-[#B8B8B8]`}></i>
            </button>

            {isOpen && (
              <div className="absolute right-[2px] top-10 mt-2 text-[#B8B8B8] w-20 rounded-md shadow-lg border border-black ring-1 ring-black ring-opacity-5 z-50">
                <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                  {languageOptions.map((language) => (
                    <button
                      key={language.value}
                      onClick={() => handleLanguageChange(language)}
                      className="flex items-center space-x-2 px-4 py-2 w-full text-left hover:bg-[#EDEDED]"
                    >
                      <span>{language.label}</span>
                    </button>
                  ))}
                </div>
              </div>
            )}
          </div> */}
        </div>
      </div>
      {isSearchVisible && searchQuery && (
        <div className="absolute top-0 left-0 w-full bg-white z-20 mt-[86px]">
          {isLoading ? (  
            <p className="text-black text-center">Loading...</p>
          ) : (
            <PaginatedItems itemsPerPage={12} items={filteredProducts} />  
          )}
        </div>
      )}
      <div
        className={`absolute left-0 w-full bg-[white] products-menu z-10 ${isHovered || isProductsOpen ? 'border-t-[1px] border-[#CFCFCF]' : ''}`}
        onMouseEnter={handleMouseEnter} // Alt menüye hover yapılınca kapanmasın
        onMouseLeave={handleMouseLeave} // Alt menüden çıkınca kapanmasın
      > 
        {(isHovered || isProductsOpen) && (
          <div className='max-w-container mx-auto flex justify-between py-[32px] font-secondFont text-firstColor text-[14px] '>
             <div className="w-[193px]">
        <a href='/products?categories=Stomatoloji+avadanlıq' className="font-bold text-[14px] font-firstFont text-firstColor">Stomatoloji avadanlıq</a>
        <ul className='space-y-3 mt-6 flex flex-col'>
          <a href='/products?brands=Owandy&categories=Stomatoloji+avadanlıq'>Owandy</a>
          <a href='/products?brands=Anthogyr&categories=Stomatoloji+avadanlıq'>Anthogyr</a>
          <a href='/products?brands=Euronda&categories=Stomatoloji+avadanlıq'>Euronda</a>
          <a href='/products?brands=Digimed&categories=Stomatoloji+avadanlıq'>Digimed</a>
          <a href='/products?brands=Ajax+Medical&categories=Stomatoloji+avadanlıq'>Ajax Medical</a>
          <a href='/products?brands=Chirana+Medical&categories=Stomatoloji+avadanlıq'>Chirana Medical</a>
          <a href='/products?brands=ToyeDental&categories=Stomatoloji+avadanlıq'>ToyeDental</a>
          <a href='/products?brands=Coxo&categories=Stomatoloji+avadanlıq'>Coxo</a>
        </ul>
      </div>
      <div className="w-[193px]">
        <a href='/products?categories=Stomatologiya' className="font-bold text-[14px] font-firstFont text-firstColor">Stomatologiya</a>
        <ul className='space-y-3 mt-6 flex flex-col'>
          <a href='/products?brands=Meta-Biomed&categories=Stomatologiya'>Meta-Biomed</a>
          <a href='/products?brands=Tokuyama+Dental&categories=Stomatologiya'>Tokuyama Dental</a>
          <a href='/products?brands=Septodont&categories=Stomatologiya'>Septodont</a>
          <a href='/products?brands=DMG&categories=Stomatologiya'>DMG</a>
          <a href='/products?brands=Cavex&categories=Stomatologiya'>Cavex</a>
          <a href='/products?brands=Shofu+Dental&categories=Stomatologiya'>Shofu Dental</a>
        </ul>
      </div>
      <div  className="w-[193px]">
        <a href='/products?categories=Lazer+avadanlığı' className="font-bold text-[14px] font-firstFont text-firstColor">Lazer avadanlığı</a>
        <ul className='space-y-3 mt-6 flex flex-col'>
          <a href='/products?brands=Candela&categories=Lazer avadanlığı'>Candela</a>
          <a href='/products?brands=Lutronic&categories=Lazer avadanlığı'>Lutronic</a>
          <a href='/products?brands=Zimmer&categories=Lazer avadanlığı'>Zimmer</a>
        </ul>
      </div>
      <div  className="w-[193px]">
        <a href='/products?categories=Kosmetologiya' className="font-bold text-[14px] font-firstFont text-firstColor">Kosmetologiya</a>
        <ul className='space-y-3 mt-6 flex flex-col'>
          <a href='/products?brands=Promoitalia&categories=Kosmetologiya'>Promoitalia</a>
          <a href='/products?brands=Koru+Pharma&categories=Kosmetologiya'>Koru Pharma</a>
          <a href='/products?brands=Fidia+Farmaceutici&categories=Kosmetologiya'>Fidia Farmaceutici</a>
          <a href='/products?brands=Medytox&categories=Kosmetologiya'>Medytox</a>
          <a href='/products?brands=Regenyal&categories=Kosmetologiya'>Regenyal</a>
          <a href='/products?brands=IBSA&categories=Kosmetologiya'>IBSA</a>
          <a href='/products?brands=Clarteis&categories=Kosmetologiya'>Clarteis</a>
        </ul>
      </div>
      <div  className="w-[193px]">
        <a href='/products?categories=Kosmetoloji avadanlıq' className="font-bold text-[14px] font-firstFont text-firstColor">Kosmetoloji avadanlıq</a>
        <ul className='space-y-3 mt-6 flex flex-col'>
          <a href='/products?brands=İonto+Comed&categories=Kosmetoloji+avadanlıq'>İonto Comed</a>
          <a href='/products?brands=Ultrasun&categories=Kosmetoloji+avadanlıq'>Ultrasun</a>
          <a href='/products?brands=Classys&categories=Kosmetoloji+avadanlıq'>Classys</a>
          <a href='/products?brands=NeoQi&categories=Kosmetoloji+avadanlıq'>NeoQi</a>
          <a href='/products?brands=Euromi&categories=Kosmetoloji+avadanlıq'>Euromi</a>
          <a href='/products?brands=Vaser&categories=Kosmetoloji+avadanlıq'>Vaser</a>
          <a href='/products?brands=Endymed&categories=Kosmetoloji+avadanlıq'>Endymed</a>
        </ul>
      </div>
          </div>
        )}
      </div>
    </>
  );
};

export default WithoutSearchLast;



