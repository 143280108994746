import React, { useEffect, useState } from 'react';
import { about1, about2, about3, about4, about5, about6, about7, about8, about9, service1, service2, service3 } from '../../assets/images'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation } from 'swiper/modules';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

const slidesData = [
  {
    id: 1,
    title: "Avadanlığın çatdırılması və quraşdırılması",
    description:
      "Arash şirkəti alınan avadanlığın Bakı şəhəri daxilində pulsuz çatdırılmasını və müştərinin iştirakı ilə yoxlanılaraq quraşdırılmasını təmin edir.",
  },
  {
    id: 2,
    title: "Avadanlığın istifadəsinin öyrədilməsi (təlim)",
    description:
      "Arash şirkətinin servis əməkdaşları müştərilərə avadanlığın düzgün istifadəsi ilə bağlı təlimatlar verirlər.",
  },
  {
    id: 3,
    title: "Avadanlığın təmiri",
    description:
      "Arash şirkəti 1 illik pulsuz zəmanət çərçivəsində avadanlıqların təmirini təmin edir və problemi yerində və ya servis mərkəzində operativ həll edir.",
  },
  {
    id: 4,
    title: "Avadanlığın montajı demontajı və s.",
    description:
      "Arash şirkəti avadanlıqların təhlükəsiz montajı və demontajı prosesini öz əməkdaşları vasitəsilə həyata keçirir.",
  },

]
const images = [service1, service2, service3];


const Service = () => {

  
    const { t} = useTranslation();

    const [list, setItems] = useState([]);
          const [isLoading, setIsLoading] = useState(true);

     useEffect(() => {
        getProducts();
      }, []);
    
      const getProducts = async () => {
        try {
          const response = await axios.get(
            `https://www.adb.az/arash/services.php`,
            { withCredentials: false }
          );
       console.log(response.data.data)
          setItems(response.data.data); 
          setIsLoading(false);
        } catch (error) {
          console.error("Error fetching products:", error);
        }
      };
 
  return ( 
    <div className='bg-[#FCFCFC]'>
   <div className='max-w-containerSm md:max-w-container mx-auto pb-[88px] md:pb-[112px]'>
    <div className='pt-[56px] text-[14px] text-firstColor flex items-center font-secondFont'>
      <p>Ana səhifə</p>
      <i className={`fas fa-chevron-right mx-2`} /> 
      <p>Servis</p>
    </div>

        <div className='w-full justify-center items-center relative mt-[48px] md:mt-[56px]'>
        <div className='md:max-w-container max-w-containerSm flex flex-col mx-auto justify-between relative'>
  
          {/* Başlık */}
          <div className='flex justify-between items-center relative'>
          <div className='w-[1040px]'>
          <p className='text-[24px] md:text-[40px] font-bold text-firstColor font-firstFont'>{t("Bizim Servis xidmətlərimiz")}</p>
          <p className=' text-secondColor mt-8 font-secondFont'>{t("Arash şirkətindən aldığınız hər bir avadanlıq və məhsul müəyyən vaxta və qaydalara əsasən sizə çatdırılır. Çatdırılma xidməti həftənin 6 günü həyata keçirilir. Arash Servis əməkdaşları müştərilər ilə əlaqə saxlayaraq uyğun zaman ərzində sifarişi ünvana çatdırırlar.")}</p>
          </div>
          <div className="absolute bottom-0 right-0 gap-2 hidden md:flex">
            <button className="swiper-button-prev-custom border-2 border-[#D9D9DE] hover:border-[#2264DC] rounded-[100px] p-[12px]">
            <i class="fa-solid fa-arrow-left text-[#2264DC]"></i>
            </button>
            <button className="swiper-button-next-custom border-2 border-[#D9D9DE] hover:border-[#2264DC] rounded-[100px] p-[12px]">
            <i class="fa-solid fa-arrow-right text-[#2264DC]"></i>
            </button>
          </div>
          </div>
  
          <div className='mt-8 md:mt-10'>
            <Swiper
              spaceBetween={30}
              loop={true}
              pagination={{ clickable: true }}
              navigation={{
                nextEl: '.swiper-button-next-custom',
                prevEl: '.swiper-button-prev-custom',
              }}
              modules={[Pagination, Navigation]}
              breakpoints={{
                480: { slidesPerView: 1 },
                640: { slidesPerView: 2 },
                768: { slidesPerView: 3 },
                1024: { slidesPerView: 3.25 },
              }}
            >
                {list.map((slide) => (
        <SwiperSlide key={slide.id}>
         <div className='md:w-[372px] w-full h-[338px] rounded-[24px] border-[1px] border-[#D9D9DE] px-[24px] py-[32px] group transition duration-300 hover:bg-[#F1F9FF]'>
                    <div className='h-[64px] w-[64px] bg-[#F1F9FF] group-hover:bg-white text-[#377BFA] group-hover:border group-hover:border-[#377BFA] rounded-[12px] text-[32px] font-bold flex justify-center items-center'>
                      {slide.id}
                    </div>
                    <p className='text-[24px] font-bold text-secondColor font-firstFont mt-8 min-h-[70px] group-hover:text-[#377BFA]'>{slide.title}</p>
                    <p className='text-[14px] text-secondColor mt-3 min-h-[80px] font-secondFont'>{slide.content}</p>
                 {/* <a href={`/service/${slide.id}`}>
                   <div className='flex justify-between items-center rounded-[16px] mt-[14px] text-[#162D59] w-[152px] h-[44px]'>
                      <p>Ətraflı bax</p>
                      <i className={`fas fa-chevron-right text-[#162D59] mr-10 group-hover:mr-3`} />
                    </div>
                   </a> */}
                  </div>
        </SwiperSlide>
      ))}
            </Swiper>
          </div>
        </div>  
      
       <div className=" flex gap-2 md:hidden w-[100px] mx-auto mt-[32px]">
            <button className="swiper-button-prev-custom border-2 border-[#D9D9DE] hover:border-[#2264DC] rounded-[100px] p-[12px]">
            <i class="fa-solid fa-arrow-left text-[#2264DC]"></i>
            </button>
            <button className="swiper-button-next-custom border-2 border-[#D9D9DE] hover:border-[#2264DC] rounded-[100px] p-[12px]">
            <i class="fa-solid fa-arrow-right text-[#2264DC]"></i>
            </button>
          </div>
          <div className='flex justify-end'>
       <a href='/contact' className='w-full md:w-auto'>
           <button className='rounded-[16px] bg-[#2264DC] mt-[40px] text-white w-full md:w-[203px] h-[44px] px-[20px] font-secondFont'>
           Müraciət et
            </button>
           </a>
       </div>
      </div>


<div className='mt-[88px] md:mt-[100px] text-[##0B0B0B]'>
  <p className='text-firstColor text-[24px] md:text-[32px] font-bold font-firstFont'>Arash Şirkətinin Çatdırılma və Zəmanət Xidməti</p>
  <p className='mt-10 font-firstFont'>Arash şirkətindən aldığınız hər bir avadanlıq və məhsul müəyyən vaxta və qaydalara əsasən sizə çatdırılır. Çatdırılma xidməti həftənin 6 günü həyata keçirilir. Arash Servis əməkdaşları müştərilər ilə əlaqə saxlayaraq uyğun zaman ərzində sifarişi ünvana çatdırırlar. Alınan məhsul və ya avadanlığın qəbulu alıcıların iştirakı ilə tam yoxlanılaraq həyata keçirilir. Sifarişlərin Bakı şəhəri daxili çatdırılması pulsuzdur. <br></br><br></br>

Avadanlıq və ya məhsul müştəri tərəfindən əldə edildikdən sonra, Araş şirkətinin servis əməkdaşı zəmanət talonunun təhvil verdiyi halda 1 illik pulsuz zəmanət xidməti göstərir. Zəmanət talonunda satış tarixi, avadanlığın və ya məhsulun adı, seriya nömrəsi göstərilməli və Araş şirkətinin nümayəndəsinin imzası olmalıdır. Həmçinin təhvil-təslim blankı tərtib edilir. <br></br><br></br>

Azərbaycanın bütün bölgələrinə çatdırılma xidməti mövcuddur. Çatdırılma xidməti öz əməkdaşlarımız tərəfindən həyata keçirildiyi üçün, məhsulların və avadanlıqların təhlükəsizliyinə və bütövlüyünə görə cavabdehlik daşıyırıq. Kriokonteynerlərdən istifadə edərək, soyuducuda saxlama üçün nəzərdə tutulan məhsulların çatdırılması zamanı təhlükəsiz çatdırılma təmin edilir. Çatdırılma müddəti bazar ertəsi – şənbə 9:00 – 19:00 aralığında həyata keçirilir.</p>
</div>
<div className='gap-8 mt-[56px] hidden md:flex'>
  {images.map((image, index) => (
    <img key={index} className='md:w-1/3' src={image} alt={`service-${index + 1}`} />
  ))}
</div>
<div className='mt-8 md:mt-10 block md:hidden'>
            <Swiper
              spaceBetween={30}
              loop={true}
              pagination={{ clickable: true }}
              navigation={{
                nextEl: '.swiper-button-next-custom',
                prevEl: '.swiper-button-prev-custom',
              }}
              modules={[Pagination, Navigation]}
              breakpoints={{
                480: { slidesPerView: 1 },
                640: { slidesPerView: 2 },
                768: { slidesPerView: 3 },
                1024: { slidesPerView: 4 },
              }}
            >
                {images.map((image, index) => (
                  <SwiperSlide key={index}>
                  <img key={index} className='md:w-1/3' src={image} alt={`service-${index + 1}`} />
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
   </div>
   </div>
  )
}
 
export default Service 